import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export interface UserPostData {
  username: string;
  password: string;
  email: string;
  first_name: string;
  last_name: string;
  staff_status: boolean;
  superuser_status: boolean;
  first_logged: boolean;
}

export interface MediatorPostData {
  user: UserPostData;
  phone: string;
  education: string;
  relevant_experience: string;
  certification_course: boolean;
  address: string;
}

export const adminApi = createApi({
  reducerPath: "adminApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `/api`,
    prepareHeaders: (headers, { getState }: { getState: () => any }) => {
      const access = getState().user.access;
      if (access) headers.set("Authorization", `JWT ${access}`);
      return headers;
    },
  }),
  endpoints(builder) {
    return {
      AddMediator: builder.mutation({
        query: (data: MediatorPostData) => {
          return {
            url: "/users/mediator/",
            method: "POST",
            body: data,
          };
        },
      }),
      AddMediatorAreas: builder.mutation({
        query: (data: { areas: string[]; mediator: number }) => {
          return {
            url: "/users//mediator-area/add_areas/",
            method: "POST",
            body: data,
          };
        },
      }),
    };
  },
});

export const { useAddMediatorMutation, useAddMediatorAreasMutation } = adminApi;
