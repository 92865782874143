import { useRef, useEffect } from "react";
import { RiShutDownLine } from "react-icons/ri";
import { VscSend } from "react-icons/vsc";
import { GroupType } from "types/messageType";

interface InputBarProps {
  handleSendMessage: (msg: string) => void;
  isShuttle: boolean;
  handleShuttle: () => void;
  role: string;
  selectedGroup: GroupType;
}

const InputBar = (props: InputBarProps) => {
  const inputRef = useRef<HTMLTextAreaElement>(null);
  
  const lineHeight = 20; // Adjust this based on your CSS line-height
  const maxLines = 3;
  const maxHeight = maxLines * lineHeight;

  const handleSendMessage = () => {
    if (!inputRef.current) return;
    const msg = inputRef.current?.value;
    if (!msg) return;
    inputRef.current.value = "";
    props.handleSendMessage(msg);
    adjustTextareaHeight(); // Reset the height when message is sent
  };

  const adjustTextareaHeight = () => {
    if (inputRef.current) {
      // Reset the height to auto to recalculate the height correctly
      inputRef.current.style.height = "auto";
      // Set the height based on the scrollHeight but limit to maxHeight
      const newHeight = Math.min(inputRef.current.scrollHeight, maxHeight);
      inputRef.current.style.height = `${newHeight}px`;
    }
  };

  useEffect(() => {
    adjustTextareaHeight(); // Adjust height on initial render
  }, []);

  return (
    <>
      {props.role === "Mediator" && (
        <button
          onClick={props.handleShuttle}
          className={` btn btn-ghost ${
            props.isShuttle ? "bg-emerald-300" : "bg-gray-500"
          } rounded-l-sm rounded-r-none`}
        >
          <RiShutDownLine
            color={props.isShuttle ? "white" : "#b3b1b1"}
            className="sm:w-2/3 sm:h-2/3 w-full h-full"
          />
        </button>
      )}

      <div
        style={{ width: "calc(100% - 4rem)" }}
        className="h-full flex items-center relative"
      >
        <textarea
          ref={inputRef}
          dir="auto"
          className="flex-grow placeholder:text-slate-500 resize-none px-2 pr-20 pt-3 bg-slate-900 text-slate-50 outline-none rounded-l-none rounded-r-sm"
          placeholder="Type a message"
          onInput={adjustTextareaHeight} // Adjust the height when the user types
          style={{ 
            minHeight: `${lineHeight}px`, // 1 line height
            maxHeight: `${maxHeight}px`,  // Maximum of 3 lines
            lineHeight: `${lineHeight}px`, // Ensure consistent line-height
            overflowY: 'auto', // Allow scrolling if text exceeds 3 lines
          }}
        />
        <button
          disabled={
            props.isShuttle &&
            props.role !== "Mediator" &&
            props.selectedGroup === "M"
          }
          onClick={handleSendMessage}
          className={`${
            props.isShuttle &&
            props.role !== "Mediator" &&
            props.selectedGroup === "M"
              ? "text-gray-600"
              : "text-emerald-300"
          } absolute right-0 btn btn-ghost rounded-none rounded-r-sm`}
        >
          <VscSend className="w-full h-2/3 rotate-[-40deg] translate-y-[-10%]" />
        </button>
      </div>
    </>
  );
};

export default InputBar;
