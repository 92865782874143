import PopButton from "../buttons/PopButton";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { useSocket } from "hooks/useSocket";
import { Textarea } from "@/components/ui/textarea";
import { motion } from "framer-motion";
import { useAppDispatch, useAppSelector } from "hooks/storHook";
import { setSummary, clearSummary } from "store";
import { MessageType } from "types/messageType";
import { useEffect, useState } from "react";
import { Emits } from "types/CaseTypes";
import { AlertDialogView } from "../alert/AlertDialog";

const SummaryStage = () => {
  const { notes, summary, initialPosition } = useAppSelector(
    (state) => state.guid
  );
  const user = useAppSelector((state) => state.user);
  const _case = useAppSelector((state) => state.case);
  const dispatch = useAppDispatch();
  const [submitSummaryOpened, setSubmitSummaryOpened] = useState(false);
  const { socket } = useSocket();

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    dispatch(setSummary(e.target.value));
  };

  const onSubmitSummary = async () => {
    const summaryMsg: MessageType = {
      message: summary,
      displayName: user.first_name + " " + user.last_name,
      side: "M",
      time: new Date(),
      from: user.id,
      to: _case.case_id,
      ext: {
        type: "guided",
        stage: "summary",
      },
      fromSide: "M",
    };
    socket?.emit("roomMsg", summaryMsg);
    dispatch(clearSummary());
  };

  useEffect(() => {
    if (!initialPosition?.A && !initialPosition?.B) return;
    socket?.emit(Emits.guid, {
      initialStateA: initialPosition?.A || "",
      initialStateB: initialPosition?.B || "",
      case_id: _case.case_id,
    });
  }, [initialPosition]);

  return (
    <motion.header
      initial={{ x: "-10%", opacity: 0 }}
      animate={{ x: "0%", opacity: 1 }}
      exit={{ x: "10%" }}
      transition={{
        duration: 0.3,
        delay: 0.3,
        ease: "backOut",
      }}
      className="px-2 flex flex-col relative overflow-x-hidden text-white"
    >
      <h3 className="text-white text-lg font-mono font-extralight text-center mb-10 ">
        Summary
      </h3>

      <p className="text-center mb-10 ">
        Summary section have intel about the negotiation that you can use.
      </p>
      <h2 className="text-xl text-center"> Initial State</h2>
      <Table>
        <TableCaption>The initial state of both parties</TableCaption>
        <TableHeader>
          <TableRow>
            <TableHead>Side A</TableHead>
            <TableHead>Side B</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          <TableRow>
            <TableCell>{initialPosition?.A}</TableCell>
            <TableCell>{initialPosition?.B}</TableCell>
          </TableRow>
        </TableBody>
      </Table>

      <Table>
        <TableCaption>Your notes</TableCaption>
        <TableHeader>
          <TableRow>
            <TableHead>#</TableHead>
            <TableHead>Party</TableHead>
            <TableHead>Note</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {notes?.map?.((note, idx) => (
            <TableRow key={idx}>
              <TableCell>{idx + 1}</TableCell>
              <TableCell>{note.groupTag}</TableCell>
              <TableCell>{note.note}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <p className="text-center my-5 text-slate-400">
        Please provide a summary for this negotiation
      </p>
      <h3 className="text-xl">Summary:</h3>
      <div className="w-full h-72 flex flex-col gap-2">
        <Textarea
          value={summary}
          onChange={handleChange}
          placeholder="Write your note here"
          className="bg-transparent border-none outline-double  focus-visible:ring-0 focus-visible:ring-offset-0 h-72 text-lg font-thin rounded-none resize-none"
        />
        <PopButton
          text={"Submit"}
          handleClick={() => setSubmitSummaryOpened(true)}
        />
        <AlertDialogView
          isOpen={submitSummaryOpened}
          onClose={(res) => {
            if (res) onSubmitSummary();
            setSubmitSummaryOpened(false);
          }}
          title="Submit Summary"
          description="Submitting the summary will end the guided negotiation and will be sent to the other parties"
        />
      </div>
    </motion.header>
  );
};
export default SummaryStage;
