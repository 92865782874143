import PopButton from "../../buttons/PopButton";
import SmallCarousel from "../../carousel/SmallCarousel";
import { AnimatePresence, motion } from "framer-motion";
import { useState, useEffect } from "react";

interface IterationStageProps {
  iterationNumber: number;
  handleContinue: () => void;
  handleSummary: () => void;
  handleAddIteration: (num: number) => void;
  handleEndNegotiation: () => void;
}

const IterationStage = (props: IterationStageProps) => {
  const [iterationColor, setIterationColor] = useState("bg-emerald-600");
  const [addIterationView, setAddIterationView] = useState(false);
  const [addNumber, setAddNumber] = useState(1);


  useEffect(() => {
   
    if (props.iterationNumber === 0 ) {
      props.handleEndNegotiation();
    }
    if (props.iterationNumber < 3) {
      setIterationColor("bg-rose-600");
    } else {
      setIterationColor("bg-emerald-600");
    }
  }, [props.iterationNumber]);

  const onAddIteration = () => {
    props.handleAddIteration(addNumber);
    setAddIterationView(false);
  };

  return (
    <div className="text-center flex flex-col items-center gap-10 justify-center mt-10">
      <h2 className="text-3xl text-white">
        Iteration Left:{" "}
        <span className={`${iterationColor} px-4 font-thin`}>
          {props.iterationNumber}
        </span>
      </h2>
      <div className="flex gap-4 justify-center h-10">
        <AnimatePresence mode="sync">
          {props.iterationNumber > 1 && (
            <motion.div key={0}
              initial={{ x: "10%", opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              exit={{ x: "-10%", opacity: 0 }}
            >
              <PopButton
                handleClick={props.handleContinue}
                text="Continue"
                color="bg-indigo-600 text-white"
                noIcon
              />
            </motion.div>
          )}
        </AnimatePresence>

        <PopButton
          handleClick={props.handleSummary}
          text="Summary"
          color="bg-violet-600 text-white"
          noIcon
        />
        <AnimatePresence mode="sync">
          {props.iterationNumber <= 2 && !addIterationView && (
            <motion.div key={1}
              initial={{ x: "10%", opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              exit={{ x: "10%", opacity: 0 }}
            >
              <PopButton
                handleClick={() => setAddIterationView(true)}
                text="Add Iteration"
                color="bg-amber-600 text-white h-full"
                noIcon
              />
            </motion.div>
          )}
          {props.iterationNumber <=1 &&(
             <motion.div key={2}
             initial={{ x: "10%", opacity: 0 }}
             animate={{ x: 0, opacity: 1 }}
             exit={{ x: "10%", opacity: 0 }}
           >
             <PopButton
               handleClick={props.handleEndNegotiation}
               text="End Negotiation"
               color="bg-rose-600 text-white h-full"
               noIcon
             />
           </motion.div>
          )}
        </AnimatePresence>
      </div>
      {addIterationView && (
        <div className="flex gap-4 justify-center items-end ">
          <SmallCarousel
            items={[
              { item: 1 },
              { item: 2 },
              { item: 3 },
              { item: 4 },
              { item: 5 },
              { item: 6 },
              { item: 7 },
              { item: 8 },
              { item: 9 },
              { item: 10 },
            ]}
            setSelection={(num) => setAddNumber(num)}
          />
          <PopButton
            handleClick={onAddIteration}
            text="Add"
            color="bg-amber-600 text-white h-10"
            noIcon
          />
        </div>
      )}
    </div>
  );
};
export default IterationStage;
