import { useAppSelector } from "hooks/storHook";
import { GroupType, LikeType, MessageType } from "types/messageType";
import Message from "./Message";
import { useEffect, useRef, useState, useMemo, useCallback } from "react";
import { Participant } from "types/userTypes";
import { Button } from "@/components/ui/button";
import { getRole } from "../../utils/utilsFunction";
import { useSocket } from "hooks/useSocket";
import { ActionMsg, ActionPayloads, ActionTypes, Emits } from "types/CaseTypes";
import BottomDrawer from "../drawer/bottomDrawer";
import { Textarea } from "@/components/ui/textarea";
import PopButton from "../buttons/PopButton";
import { useAppDispatch } from "hooks/storHook";
import { clearAction } from "store";
import { useScroll } from "framer-motion";

interface MessageBoardProps {
  selectedGroup: GroupType;
  participants: Map<string, Participant>;
  handleScroll: (progress: number) => void;
}

const MessagesBoard = (props: MessageBoardProps) => {
  const user = useAppSelector((state) => state.user);
  const messages = useAppSelector((state) => state.messages);
  const endBoardRef = useRef<HTMLDivElement>(null);
  const { actions } = useAppSelector((state) => state.chat);
  const [actionMove, setActionMove] = useState<boolean>(false);
  const { case_id } = useAppSelector((state) => state.case);
  const [initialState, setInitialState] = useState<string>("");
  const likes = useAppSelector((state) => state.like);
  const { socket } = useSocket();
  const role = getRole(user);
  const dispatch = useAppDispatch();
  const scrollRef = useRef<HTMLDivElement>(null);
  const { scrollYProgress } = useScroll({ container: scrollRef });
  const [isSmooth, setIsSmooth] = useState(true);
  const [editMsgData, setEditMsgData] = useState<{
    message_id: string;
    newMessage: string;
  } | null>(null);

  // Use a ref to store the previous scroll progress
  const previousScrollProgress = useRef(0);

  // Use useEffect instead of useMotionValueEvent to avoid re-renders
  useEffect(() => {
    const unsubscribe = scrollYProgress.on("change", (latest) => {
      if (previousScrollProgress.current !== latest) {
        previousScrollProgress.current = latest;
        props.handleScroll(latest);
      }
    });

    // Cleanup the listener on unmount
    return () => unsubscribe();
  }, [scrollYProgress, props]);

  const messageDisplay = useMemo(() => {
    setIsSmooth(false);
    return messages.filter((msg) => msg.side === props.selectedGroup);
  }, [messages, props.selectedGroup]);

  useEffect(() => {
    if (endBoardRef.current) {
      endBoardRef.current.scrollIntoView({
        behavior: isSmooth ? "smooth" : "auto",
      });
    }
  }, [messageDisplay]);

  //TODO: Add the initial state to the message board
  //add this to the notifications
  useEffect(() => {
    if (actions.includes("initialPosition") && role === "User") {
      setActionMove(true);
    } else {
      setActionMove(false);
    }
  }, [actions, role]);

  const handleClickAction = useCallback(() => {
    if (!user.side) return;
    const action: ActionMsg = {
      time: new Date(),
      actionPayload: ActionPayloads.initialPosition,
      actionType: ActionTypes.remove,
      displayName: user.first_name + " " + user.last_name,
      from: user.id,
      to: case_id,
      side: user.side,
    };
    socket?.emit(Emits.action, action);
  }, [user, case_id, socket]);

  const handleInitialStateChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setInitialState(e.target.value);
  };

  const onPassInitialState = useCallback(() => {
    if (!user.side) return;
    const action: ActionMsg = {
      time: new Date(),
      actionPayload: ActionPayloads.initialPosition,
      actionType: ActionTypes.add,
      displayName: user.first_name + " " + user.last_name,
      from: user.id,
      to: case_id,
      side: user.side,
    };
    socket?.emit(Emits.action, action);
  }, [user, case_id, socket]);

  const onSubmitInitialState = useCallback(() => {
    if (!user.side || !initialState) return;
    const msg: MessageType = {
      to: case_id,
      fromSide: user.side as GroupType,
      message: initialState,
      from: user.id,
      time: new Date(),
      side: "M",
      ext: {
        type: "guided",
        stage: "initial",
      },
      displayName: user.first_name + " " + user.last_name,
    };
    socket?.emit(Emits.roomMsg, msg);

    const payload =
      user.side === "A"
        ? ActionPayloads.receivedInitialA
        : ActionPayloads.receivedInitialB;

    const action: ActionMsg = {
      time: new Date(),
      actionPayload: payload,
      actionType: ActionTypes.add,
      displayName: user.first_name + " " + user.last_name,
      from: user.id,
      to: case_id,
      side: user.side,
      extMsg: initialState,
    };

    socket?.emit(Emits.action, action);
    dispatch(clearAction(ActionPayloads.initialPosition));
    setActionMove(false);
    setInitialState("");
  }, [user, initialState, case_id, socket, dispatch]);

  const handleSendLike = useCallback(
    (data: { msg_id: string; like: boolean }) => {
      const like: LikeType = {
        message_id: data.msg_id,
        groupSide: props.selectedGroup,
        user_id: user.id,
        displayName: user.first_name + " " + user.last_name,
        case_id: case_id,
        like: "like",
        action: data.like ? "add" : "remove",
        side: user.side || "M",
      };
      socket?.emit(Emits.like, like);
    },
    [props.selectedGroup, user, case_id, socket]
  );

  const handleEditChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setEditMsgData((prev) => {
      if (!prev) return null;
      return { ...prev, newMessage: e.target.value };
    });
  };
  const openEdit = (message_id: string, message: string) => {
    setEditMsgData({ message_id, newMessage: message });
  };
  const onSubmitEdit = () => {
    const newMsg = editMsgData?.newMessage;
    const msg_id = editMsgData?.message_id;
    if (!newMsg || !msg_id) return;
    const newMsgData = {
      message_id: msg_id,
      case_id: case_id,
      side: props.selectedGroup,
      message: newMsg,
    };
    socket?.emit(Emits.editMsg, newMsgData);
  };

  return (
    <div
      ref={scrollRef}
      className="w-full relative h-full px-2 overflow-y-scroll snap-y snap-mandatory overflow-x-hidden py-2"
    >
      {messageDisplay.map((msg, i) => {
        const likeData = likes[msg._id as string];
        const likeA = likeData?.likeData.A.length || 0;
        const likeB = likeData?.likeData.B.length || 0;
        const likeM = likeData?.likeData.M.length || 0;
        const isMeLike = likeData?.isMe || false;
        const isMe = msg.from === user.id;

        return (
          <Message
            editMessage={openEdit}
            likeA={likeA}
            likeB={likeB}
            likeM={likeM}
            ILiked={isMeLike}
            handleSendLike={handleSendLike}
            ext={msg.ext}
            side={msg.fromSide || "M"}
            key={msg._id || i}
            msg={msg}
            isMe={isMe}
          />
        );
      })}

      <BottomDrawer
        open={!!editMsgData}
        onOpenChange={(open) => {
          if (!open) setEditMsgData(null);
        }}
        drawerTitle="Edit Message"
        drawerFooter={
          <div className="flex justify-center items-center gap-4">
            <PopButton text="Submit" handleClick={onSubmitEdit} />
          </div>
        }
      >
        <div className="w-full h-full flex justify-center items-center">
          <Textarea
            onChange={handleEditChange}
            value={editMsgData?.newMessage}
            className="bg-transparent border-none outline-none w-1/2 ring-[0.3px] text-white focus-visible:ring-[0.3px] focus-visible:ring-offset-0 h-44 text-lg font-thin rounded-none resize-none"
          />
        </div>
      </BottomDrawer>

      {actionMove && (
        <BottomDrawer
          drawerTitle="Initial State"
          trigger={
            <Button
              onClick={handleClickAction}
              className="w-full bottom-0 left-0 bg-gradient-to-tr from-rose-400 to-rose-600 sticky z-10 animate-pulse hover:animate-none"
            >
              <p>CLICK TO ADD INITIAL STATE</p>
            </Button>
          }
          drawerFooter={
            <div className="flex justify-center items-center gap-4">
              <PopButton text="Submit" handleClick={onSubmitInitialState} />
              <PopButton
                text="Pass to group"
                color="bg-rose-300"
                handleClick={onPassInitialState}
              />
            </div>
          }
        >
          <div className="w-full h-full flex justify-center items-center">
            <Textarea
              onChange={handleInitialStateChange}
              placeholder="Write your initial state here"
              className="bg-transparent border-none outline-none w-1/2 ring-[0.3px] text-white focus-visible:ring-[0.3px] focus-visible:ring-offset-0 h-44 text-lg font-thin rounded-none resize-none"
            />
          </div>
        </BottomDrawer>
      )}

      <div ref={endBoardRef} id="end-board"></div>
    </div>
  );
};

export default MessagesBoard;
