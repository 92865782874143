
const Headline = ({ text }: { text: string }) => {
  return (
    <>
      <div className="relative z-10  flex flex-col items-center justify-center">
        <span className="logo-font text-lg mb-8 bg-gradient-to-r  from-emerald-500 to-emerald-500 bg-clip-text font-medium text-transparent">
          Negoflict
        </span>
        <span className="mb-8 text-5xl font-bold">{text}</span>
      </div>
      <LightHeadline/>
    </>
  );
};

export default Headline;


export const LightHeadline = () => (
  <span className="absolute -top-[350px] left-[50%] z-0 h-[500px] w-full -translate-x-[50%] rounded-full bg-gradient-to-r from-emerald-600/20 to-sky-600/20 blur-3xl" />
);
