import FormInput from "../../components/input/formInput";
import MuliSelectOptions from "../../components/select/MultiSelectOptions";
import SelectInput from "../../components/select/SelectInput";
import SelectWithInput from "../../components/select/SelectWithInput";
import { Textarea } from "@/components/ui/textarea";
import { z } from "zod";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button } from "@/components/ui/button";
import {
  MediatorPostData,
  useAddMediatorAreasMutation,
  useAddMediatorMutation,
  UserPostData,
} from "store/apis/adminApi";
import { useEffect, useState } from "react";
import { toast } from "@/components/ui/use-toast";
const AreaOfMeditation = [
  "Family",
  "Friends",
  "Neighbors",
  "Education",
  "Organizations",
  "Business",
  "Politics",
  "Other",
];

const schema = z.object({
  first_name: z.string().max(50).min(2),
  last_name: z.string().max(50).min(2),
  email: z.string().email(),
  password: z.string().min(8),
  phone: z.string().min(8),
  phone_prefix: z.string().startsWith("+").max(4).optional(),
  country: z.string(),
  address: z.string(),
  relevant_experience: z.string(),
  education: z.string(),
  certification_course: z.boolean(),
  username: z.string(),
  first_logged: z.boolean(),
  staff_status: z.boolean(),
  superuser_status: z.boolean(),
});

type FormValues = z.infer<typeof schema>;

interface AddMediatorPropStage {
  goBack: () => void;
}

const AddMediator = (props:AddMediatorPropStage) => {
  const countries = ["Israel", "USA"];
  const phone_prefix = ["+972", "+1"];
  const [AddMediator, { isLoading: addMediatorLoading, error: mediatorError }] =
    useAddMediatorMutation();
  const [addAreas, { isLoading: areaLoading, error: areaError }] =
    useAddMediatorAreasMutation();
  const [selectedOptions, setSelectedOptions] = useState<Set<string>>(
    new Set()
  );

  const {
    register,
    handleSubmit,
    setError,
    setValue,
    getValues,

    formState: { errors, isSubmitting },
  } = useForm<FormValues>({
    resolver: zodResolver(schema),
    defaultValues: {
      country: countries[0],
      address: "",
      phone_prefix: phone_prefix[0],
      username: "",
      first_logged: true,
      staff_status: true,
      superuser_status: false,
    },
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (isSubmitting || areaLoading || addMediatorLoading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [isSubmitting, areaLoading, addMediatorLoading]);

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    data.phone = data.phone_prefix + data.phone;
    delete data.phone_prefix;
    data.username = data.email.replace(/[@.]|[^a-zA-Z0-9]/g, "");

    const userData: UserPostData = {
      username: data.username,
      password: data.password,
      email: data.email,
      first_name: data.first_name,
      last_name: data.last_name,
      staff_status: data.staff_status,
      superuser_status: data.superuser_status,
      first_logged: data.first_logged,
    };

    const mediatorData: MediatorPostData = {
      user: { ...userData },
      phone: data.phone,
      education: data.education,
      relevant_experience: data.relevant_experience,
      certification_course: data.certification_course,
      address: data.address,
    };

    const areas = Array.from(selectedOptions);

    try {
      const mediatorPayload = await AddMediator(mediatorData).unwrap();
      const mediator = mediatorPayload?.mediator?.user?.id || null;
      if (areas.length > 0 && mediator) {
        await addAreas({ areas, mediator }).unwrap();
      }

      toast({
        variant: "default",
        className: "bg-[#1D232A] text-white",
        title: "Success",
        description: "Mediator added successfully",
      });
      props.goBack();
    } catch (error:any) {
      console.error("rejected", error);
      const errorMsg = error?.data?.email?.[0] || ''
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to add mediator." + '\n'+errorMsg,
      });
    }
  };

  return (
    <div className="mx-auto w-full  mt-12 max-w-3xl pb-12   backdrop-brightness-125 shadow-xl rounded-md p-2 ">
      <center className="text-black text-2xl">Add Mediator</center>
      <form onSubmit={handleSubmit(onSubmit)}>
        <center className="">
          {/* row 1 */}
          <div className="grid-flow-col grid grid-col-2 ">
            <div className="w-full h-full flex justify-center">
              <FormInput
                register={register}
                type="text"
                name="first_name"
                autoComplete="given-name"
                placeHolder="First name"
                rightLabel="Required"
                bottomLabel={errors.first_name?.message}
              />
            </div>
            <div className="w-full h-full flex justify-center">
              <FormInput
                register={register}
                type="text"
                autoComplete="family-name"
                name="last_name"
                placeHolder="Last name"
                rightLabel="Required"
                bottomLabel={errors.last_name?.message}
              />
            </div>
          </div>
          {/* row 2 */}
          <div className="grid-flow-col grid grid-cols-2 ">
            <div className="w-full h-full flex justify-center">
              <FormInput
                register={register}
                type="email"
                name="email"
                placeHolder="Email"
                rightLabel="Required"
                autoComplete="email"
                bottomLabel={errors.email?.message}
              />
            </div>
            <div className="w-full h-full flex justify-center">
              <FormInput
                register={register}
                secure
                name="password"
                placeHolder="password"
                rightLabel="Required"
                autoComplete="current-password"
                bottomLabel={errors.password?.message}
              />
            </div>
          </div>
          {/* row 3 */}
          <div className="grid-flow-col grid-cols-2 h-24 grid  ">
            <div>
              <SelectWithInput
                onChange={(name, value) =>
                  setValue(name as keyof FormValues, value)
                }
                selectAutoComplete="tel-area-code"
                autoComplete="tel"
                register={register}
                name="phone"
                type="tel"
                selectName="phone_prefix"
                options={phone_prefix}
              />
              <div className="h-[1rem] -mt-6">
                {errors.phone ? (
                  <span className="text-rose-400 text-sm">
                    {errors.phone.message}
                  </span>
                ) : (
                  errors.phone_prefix && (
                    <span className="text-rose-400 text-sm">
                      {errors.phone_prefix.message}
                    </span>
                  )
                )}
              </div>
            </div>

            <div className="w-full h-full flex justify-center items-center  ">
              <div className=" w-full">
                <SelectInput
                  autoComplete="country"
                  onChange={(name, value) =>
                    setValue(name as keyof FormValues, value)
                  }
                  name="country"
                  label="COUNTRY"
                  options={countries}
                />
                <div className="h-[1rem]">
                  {errors.country && (
                    <span className="text-sm text-rose-400">
                      {errors.country.message}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* row 4 */}
          <div className="grid-cols-2 grid">
            <div className="w-full h-full">
              <FormInput
                className=""
                register={register}
                type="text"
                name="address"
                placeHolder="Address"
                bottomLabel={errors.address?.message}
              />
            </div>
          </div>
          {/* row 5 */}

          <div className="w-full  flex flex-col ">
            <div className="w-full h-1 px-2 border-t-[0.5px] border-dashed my-4" />
            <div className="w-full h-full flex justify-start ">
              <Textarea
                {...register("relevant_experience")}
                placeholder="Relevant Experience"
                className="bg-[#1D232A] border-none flex-1 outline-double  focus-visible:ring-0 focus-visible:ring-offset-0 h-72 text-lg font-thin rounded-none resize-none"
              />
              <div className="flex-1 flex-col flex   items-center  gap-5 ">
                <MuliSelectOptions
                  selectedOptions={selectedOptions}
                  setSelectedOptions={setSelectedOptions}
                  options={AreaOfMeditation}
                />

                <FormInput
                  className=""
                  register={register}
                  type="text"
                  name="education"
                  placeHolder="Education"
                  rightLabel="Required"
                  leftLabel={errors.education?.message}
                />
                <div className="flex justify-start items-center ml-2 gap-4 mt-12 h-fit px-4">
                  <input
                    type="checkbox"
                    className=" checkbox-success h-4 w-4"
                    {...register("certification_course")}
                  />
                  <p className="text-xl text-black font-light ">
                    Negoflict Certificate
                  </p>
                </div>
                <Button
                  type="submit"
                  variant="default"
                  className="btn btn-outline transition-all duration-150 ease-in-out bg-gradient-to-tr from-indigo-500 to-violet-500 shadow-sm max-w-full w-1/2"
                >
                  {isSubmitting || isLoading ? (
                    <div className="flex justify-center items-center">
                      <p>Loading</p>
                      <span className="loading loading-ring loading-xs"></span>
                    </div>
                  ) : (
                    <p> Submit</p>
                  )}
                </Button>
              </div>
            </div>
          </div>
        </center>
      </form>
    </div>
  );
};

export default AddMediator;
