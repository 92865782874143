import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";

import { Provider } from "react-redux";
import { store, persistor } from "./store/index";
import { PersistGate } from "redux-persist/integration/react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { SocketProvider } from "hooks/useSocket";
import AdminPage from "pages/AdminPage/AdminPage";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <Provider store={store}>
    <PersistGate loading={<h1>loading...</h1>} persistor={persistor}>
      <BrowserRouter>
        <SocketProvider>
          <Routes>
            <Route path="*" element={<App />} />
          </Routes>
        </SocketProvider>
      </BrowserRouter>
    </PersistGate>
  </Provider>
);
