import { useEffect, useState } from "react";
import { CaseReturnType, Emits } from "types/CaseTypes";
import { useAppSelector, useAppDispatch } from "hooks/storHook";
import {
  useGetMyCasesQuery,
  useSetCaseLinkMutation,
  useDeleteCaseLinkMutation,
  useCloseCaseMutation,
  useDeleteCaseMutation,
} from "store/apis/caseApi";
import { useNavigate } from "react-router-dom";
import { clearCase, clearGuid, clearMessages, setCase } from "store";
import { useSocket } from "hooks/useSocket";
import DashboardView from "./dashboardView";

// const bg = "bg-[#414a4f]";

export const TabsFAQ = () => {
  const [selected, setSelected] = useState<string>("");
  const [tabs, setTabs] = useState<string[]>([]);
  const [dialogResolve, setDialogResolve] = useState<
    ((value: boolean) => void) | null
  >(null);
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user);
  const [dialogShow, setDialogShow] = useState(false);
  const caseActive = useAppSelector((state) => state.case);
  const { socket } = useSocket();
  const [setCaseLink] = useSetCaseLinkMutation();
  const [deleteCaseLink] = useDeleteCaseLinkMutation();
  const [messageIdx, setMessageIdx] = useState<number>(0); //0 for active case, 1 for close case
  const [closeCase] = useCloseCaseMutation();
  const [deleteCase] = useDeleteCaseMutation();

  const navigator = useNavigate();
  const {
    data: casesData,
    error: errorFetchingCases,
    isLoading,
  } = useGetMyCasesQuery(user.id);
  const [cases, setCases] = useState<{ [key: string]: CaseReturnType[] }>({});

  useEffect(() => {
    if (!casesData?.length) return;
    if (!caseActive.case_id || caseActive.case_title) return;
    const activeCase = casesData.find((c) => c.id === caseActive.case_id);
    if (activeCase && activeCase.is_active) {
      dispatch(setCase({ case_title: activeCase.title }));
    }
  }, [caseActive, casesData]);

  useEffect(() => {
    if (errorFetchingCases) return;
    if (casesData) {
      splitByActive(casesData);
    }
  }, [casesData]);

  useEffect(() => {
    if (tabs.length === 0) return;
    if (selected) return;
    setSelected(tabs[0]);
  }, [tabs]);

  const handleStartCase = async (id: string) => {
    if (!socket) return;
    let canStart = true;
    if (caseActive.case_id && caseActive.case_id !== id) {
      setMessageIdx(0);
      const response = await handleGetDialog();
      canStart = response;
      if (response) {
        closeActiveCase();
      }
    }
    if (!canStart) return;

    const _case = casesData?.find((c) => c.id === id);
    if (!_case?.id) return;

    const caseLink = _case.id?.toString().slice(-6);

    if (_case) {
      dispatch(
        setCase({
          case_id: _case.id,
          case_title: _case.title,
          link: caseLink,
        })
      );
    }

    setCaseLink({ case_id: _case.id.toString(), link_id: caseLink })
      .then(() => {
        moveToChat(id);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const closeActiveCase = () => {
    const link_id = caseActive.case_id.toString().slice(-6);
    deleteCaseLink({ link_id })
      .then((res) =>
        socket?.emit(Emits.endCase, { case_id: caseActive.case_id })
      )
      .catch((err) => console.log(err));
  };

  const moveToChat = (id: string) => {
    navigator("/Mediator/chat", {
      state: { case_id: id },
    });
  };

  const handleGetDialog = async () => {
    setDialogShow(true);
    return new Promise<boolean>((resolve) => {
      setDialogResolve(() => resolve);
    });
  };

  const handleDialogClose = (accept: boolean) => {
    if (dialogResolve) {
      if (accept) {
        //TODO: close the current case emit to close case and the api to close the case
        // socket.emit(Emits.CLOSE_CASE, { case_id: caseActive.case_id });
        dispatch(clearCase());
        dispatch(clearMessages());
        dispatch(clearGuid());
      }
      dialogResolve(accept);
    }
    setDialogShow(false);
  };

  const splitByActive = (cases: CaseReturnType[]) => {
    const modData: { [key: string]: CaseReturnType[] } = {};

    cases.forEach((_case) => {
      const key = _case.is_active ? "Opened Cases" : "Closed Cases";

      if (!modData[key]) {
        modData[key] = [];
      }
      modData[key].push(_case);
    });

    setCases(modData);
    setTabs([...Object.keys(modData)].sort((a, b) => b.localeCompare(a)));
  };

  const handleInfo = (id: string) => {
    navigator(`/Mediator/case/${id}`);
  };

  const handleCloseCase = async (id: string) => {
    setMessageIdx(1);
    const response = await handleGetDialog();
    if (!response) return;
    if (caseActive.case_id === id) {
      closeActiveCase();
    }

    closeCase(id)
      .then(() => {
        const _cases = casesData?.filter((c) => c.id !== id);
        if (_cases) {
          splitByActive(_cases);
        }
      })
      .catch((err) => console.log(err));
  };

  const handleDeleteCase = async (id: string) => {
    setMessageIdx(2);
    const response = await handleGetDialog();
    if (!response) return;
    deleteCase(id)
      .then(() => {
        const _cases = casesData?.filter((c) => c.id !== id);
        if (_cases) {
          splitByActive(_cases);
        }
      })
      .catch((err) => console.log(err));
  };
  const handleEndCase = async (id: string) => {
    setMessageIdx(3);
    const response = await handleGetDialog();
    if (!response) return;
    closeActiveCase();
  };

  return (
    <DashboardView
      handleEndCase={handleEndCase}
      handleDeleteCase={handleDeleteCase}
      handleCloseCase={handleCloseCase}
      messageDialogIdx={messageIdx}
      handleInfo={handleInfo}
      isLoading={isLoading}
      casesData={casesData}
      dialogShow={dialogShow}
      selected={selected}
      setSelected={setSelected}
      handleDialogClose={handleDialogClose}
      cases={cases}
      handleStartCase={handleStartCase}
      moveToChat={moveToChat}
      tabs={tabs}
    />
  );
};

const TABS = ["Opened cases", "Close cases"];

// create_at , title, category, sub_category, problem_brief, close_at,is_active,
