import CircleButton from "../../components/buttons/CircleButton";
import { BiLogOut } from "react-icons/bi";
import { IoMdChatboxes } from "react-icons/io";
import { MdOutlineSettings } from "react-icons/md";
import { PiBookFill } from "react-icons/pi";
import { GroupType } from "types/messageType";
import GroupHandler from "../chat/groupHandler";
import { IoShareOutline } from "react-icons/io5";
import NakedSmallButton from "../../components/buttons/NakedSmallButton";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "hooks/storHook";
import { getRole } from "../../utils/utilsFunction";
import PopOver from "../../components/dropdown/PopOver";
import ShareCase from "../../components/alert/shareCase";
import useMobile from "hooks/useMobile";
import CostumeDialog from "../../components/dialog/genericDialog";
import { RiGuideFill, RiTextWrap } from "react-icons/ri";
import { MdEditNote } from "react-icons/md";
import DrawerLeft from "../../components/drawer/DrawerLeft";

interface SideContentProps {
  handleChangeGroup: (group: GroupType) => void;
  selectedGroup: GroupType;
  handleSelectInfo: (idx: number) => void;
  selectedInfo: number;
}

const SideContent = (props: SideContentProps) => {
  //hooks=======================
  const isMobile = useMobile();
  const navigator = useNavigate();
  const user = useAppSelector((state) => state.user);
  const role = getRole(user);

  //state=======================

  const returnToDashboard = () => {
    if (role === "Mediator") {
      console.log("Mediator");
      navigator("/Mediator/dashboard");
    } else {
      navigator("/login");
    }
  };

  return (
    <div
      id="side-content"
      className="divide-y  divide-dashed h-full divide-slate-300    grid grid-rows-8 max-h-full overflow-hidden   "
    >
      <header
        className={`flex  flex-col sm:gap-5 w-full ${
          role === "User" ? "justify-start" : "justify-center"
        } items-center row-span-3 gap-3 py-2  sm:row-span-3 `}
      >
       
          <TopMenu
            selectedInfo={props.selectedInfo}
            role={role}
            handleSelectInfo={props.handleSelectInfo}
          />
        
      </header>

      <center
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
        className="w-full  h-full  gap-4 row-span-3   "
      >
        <GroupHandler
          role={role}
          handleChangeGroup={props.handleChangeGroup}
          selectedGroup={props.selectedGroup}
        />
      </center>
      <footer className="w-full grid grid-rows-3   items-center justify-evenly gap-3 row-span-2 ">
        {/* share */}
        {isMobile ? (
          <CostumeDialog
            trigger={<NakedSmallButton icon={<IoShareOutline />} />}
            children={<ShareCase />}
          ></CostumeDialog>
        ) : (
          <PopOver
            className="border-2 rounded-md p-2"
            trigger={<NakedSmallButton icon={<IoShareOutline />} />}
          >
            <ShareCase />
          </PopOver>
        )}

        {/* settings */}
        <NakedSmallButton icon={<MdOutlineSettings />} onClick={() => {}} />
        {/* logout */}
        <div className="pr-[0.3rem]">
          <NakedSmallButton icon={<BiLogOut />} onClick={returnToDashboard} />
        </div>
      </footer>
    </div>
  );
};

export default SideContent;

interface TopMenuProps {
  selectedInfo: number;
  handleSelectInfo: (idx: number) => void;
  role: "User" | "Mediator" | "Admin";
}

const TopMenu = (props: TopMenuProps) => {
  return (
    <>
      <CircleButton
        active={props.selectedInfo}
        onClick={props.handleSelectInfo}
        idx={0}
        icon={<IoMdChatboxes />}
      />

      {props.role === "Mediator" && (
        <>
          <CircleButton
            active={props.selectedInfo}
            onClick={props.handleSelectInfo}
            idx={1}
            icon={<PiBookFill />}
          />
          <CircleButton
            active={props.selectedInfo}
            onClick={props.handleSelectInfo}
            idx={2}
            icon={<RiGuideFill />}
          />
          <CircleButton
            active={props.selectedInfo}
            onClick={props.handleSelectInfo}
            idx={3}
            icon={<RiTextWrap />}
          />
          <CircleButton
            active={props.selectedInfo}
            onClick={props.handleSelectInfo}
            idx={4}
            icon={<MdEditNote />}
          />
        </>
      )}
    </>
  );
};
