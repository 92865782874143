import { Button } from "@/components/ui/button";
import { TbCircleLetterAFilled, TbCircleLetterBFilled } from "react-icons/tb";

interface StateWaitingInitialGuidProps {
    initialReceived: { A: boolean; B: boolean };
    sendInitialAgain: ({ side }: { side: string }) => void;
    setContinueAnyWay: () => void;
  }
  
  const StateWaitingInitialGuid = (props: StateWaitingInitialGuidProps) => {
    return (
      <div className="text-white ">
        <h3>Waiting for both parties to </h3>
        <StatusWaitingInitialGuid
          sendInitialAgain={props.sendInitialAgain}
          status={props.initialReceived.A ? "Received" : "Waiting"}
          side="A"
        />
        <StatusWaitingInitialGuid
          sendInitialAgain={props.sendInitialAgain}
          status={props.initialReceived.B ? "Received" : "Waiting"}
          side="B"
        />
  
        <Button
          variant="ghost"
          className="bg-gradient-to-tr text-xs mt-5 from-emerald-400 to-emerald-600"
          onClick={props.setContinueAnyWay}
        >
          Continue Without initialState
        </Button>
      </div>
    );
  };
  
  interface StartWaitingViewProps {
    status: "Waiting" | "Received";
    side: "A" | "B";
    sendInitialAgain: ({ side }: { side: string }) => void;
  }
  const StatusWaitingInitialGuid = (props: StartWaitingViewProps) => {
    const icon =
      props.side === "A" ? (
        <TbCircleLetterAFilled className="w-16 h-16 text-teal-600" />
      ) : (
        <TbCircleLetterBFilled className="w-16 h-16 text-rose-600" />
      );
  
    const statusColor =
      props.status === "Waiting" ? "text-white" : "text-emerald-300";
  
    return (
      <div className="w-full flex justify-start items-center gap-4 ">
        {icon}
        <p className={statusColor}>{props.status}</p>
        {props.status === "Waiting" && (
          <>
            <Button
              variant="ghost"
              className="bg-gradient-to-tr text-xs from-rose-400 to-rose-600"
              onClick={() => props.sendInitialAgain({ side: props.side })}
            >
              Send Again
            </Button>
          </>
        )}
      </div>
    );
  };
  
  export { StateWaitingInitialGuid, StatusWaitingInitialGuid };