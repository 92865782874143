import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { LikeType, MessageType } from "types/messageType";

const initialState: MessageType[] = [];

const MessageSlice = createSlice({
  name: "messages",
  initialState,
  reducers: {
    initialMessages: (state, action: PayloadAction<MessageType[]>) => {
      return action.payload;
    },
    addMessage: (state, action: PayloadAction<MessageType>) => {
      state.push(action.payload);
    },
    clearMessages: (state) => {
      return [];
    },
    editMsg: (
      state,
      action: PayloadAction<{
        message_id: string;
        case_id: string;
        side: string;
        message: string;
      }>
    ) => {
      const { message_id, message } = action.payload;
      const idxMsg = state.findIndex((msg) => msg._id === message_id);
      if (idxMsg === -1) return;
      state[idxMsg].message = message;
    },
  },
});

export const { initialMessages, addMessage, clearMessages, editMsg } =
  MessageSlice.actions;
export const messageReducer = MessageSlice.reducer;
