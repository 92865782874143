import AdminNavBar from "../../components/navBar/AdminNavBar";
import Headline from "../../components/background/headline";
import { Button } from "@/components/ui/button";
import { FaChevronLeft } from "react-icons/fa";
import {  useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import LinkMenu from "../../components/lists/LinkMenu";
import AddMediator from "./AddMediatorStage";

const AdminPage = () => {
  const [menuPageIdx, setMenuPageIdx] = useState<number>(-1);

  return (
    <>
      <AdminNavBar />
      <section className="w-full  flex flex-col h-[100dvh] overflow-auto bg-[#414a4f] px-4 py-12 text-slate-50">
        <Headline text="Welcome Admin." />
        <AnimatePresence mode="popLayout">
          {menuPageIdx < 0 ? (
            <motion.div
              key={1}
              exit={{
                x: -100,
                opacity: 0,
                transition: { duration: 0.4, ease: "backInOut" },
              }}
            >
              <LinkMenu
                text={[
                  "Create new Mediator",
                  "Manage Accounts",
                  "Statistics",
                  "Online Cases",
                ]}
                onClick={(idx) => setMenuPageIdx((prev) => idx)}
              />
            </motion.div>
          ) : (
            <motion.div
              key={2}
              initial={{ x: 100, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              exit={{
                x: -100,
                opacity: 0,
                transition: { duration: 0.4, ease: "backInOut" },
              }}
              className="mx-auto w-full z-[1000]   cursor-pointer   max-w-3xl"
            >
              <Button
                variant="ghost"
                onClick={() => setMenuPageIdx((prev) => -1)}
                className="hover:bg-transparent  hover:rounded-none hover:backdrop-brightness-150   "
              >
                <FaChevronLeft className="text-3xl text-black w-8 h-8" />
              </Button>
            </motion.div>
          )}
          {menuPageIdx === 0 && (
            <motion.div
              initial={{ x: 100, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              exit={{ x: -100, opacity: 0 }}
            >
              <AddMediator goBack={() => setMenuPageIdx(-1)} />
            </motion.div>
          )}
        </AnimatePresence>
      </section>
    </>
  );
};
export default AdminPage;
