import { Button } from "@/components/ui/button";
import Headline from "../../components/background/headline";
import { Link } from "react-router-dom";
import { MdArrowBackIosNew } from "react-icons/md";


const StatusPage = ({ status }: { status: string }) => {
  return (
    <section className="w-full flex flex-col h-[100dvh] overflow-hidden bg-[#414a4f] px-4 py-12 text-slate-50">
      <Headline text={status || ""} />
      <center className="flex justify-center w-full gap-4 flex-col">
        <p className="text-center text-xl">
          Please contact the admin for further assistance.
        </p>

        <Link to="/login">
          <Button className=" bg-gradient-to-tr from-indigo-500 to-violet-500">
            <MdArrowBackIosNew />
            <span>Back to login page</span>
          </Button>
        </Link>
      </center>
    </section>
  );
};
export default StatusPage;
