import { NoteType } from '../../components/chat/GuidedNegotiation/GuidedUtils';
import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface IGuid {
  stage: number;
  iteration: number;
  initialIteration: number;
  hasStarted: boolean;
  notes: NoteType[];
  summary: string;
  initialPosition?: { A: string; B: string };
}
const initialSummary =
  "Party A and Party B, I am glad that you agreed to discuss an important and challenging topic. Let me summarize: ...";

const initialState: IGuid = {
  stage: 0,
  iteration: 0,
  initialIteration: 0,
  hasStarted: false,
  notes: [],
  summary: initialSummary,
  initialPosition: {
    A: "",
    B: "",
  },
};

const GuidSlice = createSlice({
  name: "guid",
  initialState,
  reducers: {
    changeIteration: (state, action: PayloadAction<number>) => {
      const iterationNumber = (state.iteration += action.payload);
      if (iterationNumber <= 0) {
        state.iteration = 0;
        state.initialIteration = 0;
        state.stage = 0;
        state.hasStarted = false;
      } else {
        state.iteration = iterationNumber;
      }
    },
    setGuidedData: (state, action: PayloadAction<Partial<IGuid>>) => {
      return { ...state, ...action.payload };
    },
    addIteration: (state, action: PayloadAction<number>) => {
      state.initialIteration += action.payload;
    },
    setGuidedNegotiation: (
      state,
      action: PayloadAction<{ isStarted: boolean; iteration: number }>
    ) => {
      let stateChange = { ...state };
      if (!action.payload.isStarted) {
        stateChange.stage = 0;
        stateChange.iteration = 0;
        stateChange.initialIteration = 0;
        stateChange.initialPosition = { A: "", B: "" };
      } else {
        stateChange.initialIteration = action.payload.iteration;
        stateChange.iteration = action.payload.iteration;
      }
      stateChange.hasStarted = action.payload.isStarted;
      return stateChange;
    },
    setGuidedNegotiationStage: (state, action: PayloadAction<number>) => {
      state.stage = action.payload;
    },
    setNotes: (state, action: PayloadAction<NoteType[]>) => {
      state.notes = [...action.payload];
    },
    setSummary: (state, action: PayloadAction<string>) => {
      state.summary = action.payload;
    },
    clearSummary: (state) => {
      state.summary = initialSummary;
    },
    clearGuid: (state) => {
      return initialState;
    },
    addInitialPosition: (
      state,
      action: PayloadAction<Partial<{ A: string; B: string }>>
    ) => {
      state.initialPosition = {
        A: action.payload.A || state.initialPosition?.A || "",
        B: action.payload.B || state.initialPosition?.B || "",
      };
    },
  },
});

export const {
  changeIteration,
  setGuidedNegotiation,
  setGuidedNegotiationStage,
  addIteration,
  setNotes,
  setSummary,
  clearSummary,
  clearGuid,
  addInitialPosition,
  setGuidedData,
} = GuidSlice.actions;
export const guidReducer = GuidSlice.reducer;
