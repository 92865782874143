import CaseCollapse from "../../components/lists/caseCollapse";
import NavBar from "../../components/navBar/navBar";
import Headline from "../../components/background/headline";
import Tabs from "../../components/listTab/tabs";
import { AlertDialogView } from "../../components/alert/AlertDialog";
import LoadingPage from "pages/loadingPage/loadingPage";
import { AnimatePresence, motion } from "framer-motion";
import { Dispatch, SetStateAction } from "react";
import { Logo } from "pages/login/LoginPageView";
import BubbleBackground from "../../components/background/BubbleBackground";
interface DashboardViewProps {
  isLoading: boolean;
  casesData: any;
  dialogShow: boolean;
  messageDialogIdx: number;
  selected: string;
  cases: any;
  handleDialogClose: (accept: boolean) => void;
  setSelected: Dispatch<SetStateAction<string>>;
  handleStartCase: (id: string) => void;
  moveToChat: (id: string) => void;
  tabs: string[];
  handleInfo: (id: string) => void;
  handleCloseCase: (id: string) => void;
  handleDeleteCase: (id: string) => void;
  handleEndCase: (id: string) => void;
}
const DashboardView = (props: DashboardViewProps) => {
  const dialogMessages = [
    {
      title: "You have an active case",
      description: "You have an active case, do you want to continue with it?",
    },
    {
      title: "Close case",
      description:
        "Closing the case will resolve the case and you will not be able to access it again.",
    },
    {
      title: "Delete case",
      description:
        "Deleting the case will remove the case from the list and remove all the data,\n you will not be able to access it again.",
    },
    {
      title: "End case",
      description:
        "Ending the case will resolve the case and force all parties to leave the case.",
    }
  ];

  return (
    <>
      <motion.div>
        <NavBar closeCase={props.handleEndCase} moveBackToChat={props.moveToChat} />
        <section className="w-full flex flex-col h-[100dvh] overflow-hidden bg-[#414a4f] px-4 py-12 text-slate-50">
          <Headline text="Welcome Mediator." />
          <AlertDialogView
            title={dialogMessages[props.messageDialogIdx].title}
            description={dialogMessages[props.messageDialogIdx].description}
            isOpen={props.dialogShow}
            onClose={props.handleDialogClose}
          />
          <AnimatePresence>
            {props.isLoading && (
              <motion.div
                key={33}
                initial={{ opacity: 1 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0, transition: { delay: 0.6, duration: 0.5 } }}
                className=" fixed w-full h-full  left-[50%] top-[50%] z-50  translate-x-[-50%] translate-y-[-50%]"
              >
                <BubbleBackground>
                  {Logo}
                  <h1 className="text-xl mt-5">
                    Loading{" "}
                    <span className="loading align-bottom loading-dots loading-sm text-white"></span>
                  </h1>
                </BubbleBackground>
              </motion.div>
            )}
            {props.selected && (
              <>
                <Tabs
                  key={2}
                  selected={props.selected}
                  setSelected={props.setSelected}
                  tabsMap={props.tabs}
                />
                <div className="flex w-full flex-grow overflow-scroll">
                  <CaseCollapse
                    key={3}
                    handleDeleteCase={props.handleDeleteCase}
                    handleCloseCase={props.handleCloseCase}
                    handleInfo={props.handleInfo}
                    selected={props.selected}
                    cases={props.cases}
                    handleStartCase={props.handleStartCase}
                  />
                </div>
              </>
            )}
          </AnimatePresence>
        </section>
      </motion.div>
    </>
  );
};
export default DashboardView;
