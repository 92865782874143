import { CaseReturnType } from "types/CaseTypes";
import InfoCard from "../card/infoCard";
import { motion } from "framer-motion";

interface CaseInfoProps {
  caseInfo?: CaseReturnType;
}

const CaseInfo = (props:CaseInfoProps) => {
  return (
    <motion.header
      initial={{ x: "-10%", opacity: 0 }}
      animate={{ x: "0%", opacity: 1 }}
      exit={{ x: "10%" }}
      transition={{
        duration: 0.3,
        delay: 0.3,
        ease: "backOut",
      }}
      className="px-2 flex flex-col relative overflow-x-hidden"
    >
      <h3 className="text-white text-lg font-mono font-extralight text-center">
        Case Info
      </h3>

      <InfoCard caseData={props.caseInfo} />
    </motion.header>
  );
};
export default CaseInfo;
