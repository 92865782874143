import SmallCarousel from "../../carousel/SmallCarousel";
import { useState } from "react";
import { ContinueButtons } from "./GuidedUtils";

const InitialStage = (props: {
  handleStartNegotiation: (idx: number) => void;
  handleChangeStage: (idx: number) => void;
  onInitChange: (
    data: Partial<{
      startWith: string;
      iterationNumber: number;
    }>,
    name: "startWith" | "iterationNumber"
  ) => void;
  initData: { startWith: string; iterationNumber: number };
}) => {
  const groups = [
    { item: "A", color: "bg-teal-600" },
    { item: "B", color: "bg-rose-600" },
  ];
  const numbers = [
    { item: 2 },
    { item: 3 },
    { item: 4 },
    { item: 5 },
    { item: 6 },
    { item: 7 },
    { item: 8 },
    { item: 9 },
    { item: 10 },
  ];

  const onSelectedNumber = (item: any) => {
    props.onInitChange(item, "iterationNumber");
  };

  const onSelectedGroup = (item: any) => {
    props.onInitChange(item, "startWith");
  };

  const handleStartNegotiation = (idx: number) => {
    const numOfIteration = props.initData.iterationNumber;
    props.handleChangeStage(idx);
    if (idx < 2) return;
    props.handleStartNegotiation(numOfIteration);
  };

  return (
    <div className="w-full h-full grid grid-rows-5">
      <div className="w-full h-full flex flex-col gap-5 row-span-2  ">
        <div className="flex justify-around gap-4">
          <h3 className="text-white w-3/4">
            Select the number of interactions <br />{" "}
            <span className="text-zinc-300 text-sm">
              It's recommended to have 5-8 interactions
            </span>{" "}
          </h3>
          <SmallCarousel initState={props.initData.iterationNumber} setSelection={onSelectedNumber} items={numbers} />
        </div>
        <div className="flex gap-4 justify-around">
          <h3 className="text-white w-3/4">
            Select the party to begin with <br />{" "}
          </h3>
          <SmallCarousel initState={props.initData.startWith}  setSelection={onSelectedGroup} items={groups} />
        </div>
      </div>
      <div className="row-span-2">
        <h2 className="text-white text-xl underline underline-offset-4 mb-2">
          Initial Position Message:
        </h2>
        <p className="text-white">
          Party A and Party B, thank you very much.
          <br />
          You have{" "}
          <span className="text-xl text-emerald-300">
            {props.initData.iterationNumber}
          </span>{" "}
          interactions for this session.
          <br />
          party{" "}
          <span
            className={`text-xl px-2 ${
              groups.find((g) => g.item === props.initData.startWith)?.color ||
              ""
            } `}
          >
            {props.initData.startWith}
          </span>{" "}
          begins.
        </p>
      </div>
      <div className="row-span-1">
        <ContinueButtons
          initIndex={2}
          handleChangeStage={handleStartNegotiation}
        />
      </div>
    </div>
  );
};
export default InitialStage;
