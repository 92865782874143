import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { recommendation } from "../../utils/recomendationData";
import { motion } from "framer-motion";

const RecommendationView = () => {
  return (
    <>
      <h1 className="text-lg font-mono text-center mb-3  text-white">
        Recommendation
      </h1>
      <div className="h-full ">
        <Accordion
          type="single"
          collapsible
          className="w-full min-h-fit text-white "
        >
          {recommendation.map((rec, i) => {
            return (
              <motion.div
                initial={{ x: "-10%", opacity: 0 }}
                animate={{ x: "0%", opacity: 1 }}
                exit={{ x: "10%" }}
                transition={{
                  duration: 0.3,
                  delay: 0.3 + i * 0.08,
                  ease: "backOut",
                }}
                className="z-2"
                key={i}
              >
                <AccordionItem
                  className=" border-slate-950 divide-y-2 divide-black"
                  value={`rec-${i}`}
                >
                  <AccordionTrigger>{rec.barrier}</AccordionTrigger>
                  {rec.solutions.map((sol, j) => {
                    return (
                      <AccordionContent
                        className="bg-slate-300  text-zinc-950 py-2 "
                        key={j}
                      >
                        <p className="text-left text-pretty p-2 font-semibold">
                          {sol}
                        </p>
                      </AccordionContent>
                    );
                  })}
                </AccordionItem>
              </motion.div>
            );
          })}
        </Accordion>
      </div>
    </>
  );
};
export default RecommendationView;
