import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { Socket } from "socket.io-client";

interface ISocket {
  socket?: Socket;
}
const initialState: ISocket = {};

const SocketSlice = createSlice({
  name: "socket",
  initialState,
  reducers: {
    setSocket: (state, action: PayloadAction<Socket>) => {
      return { socket: action.payload };
    },
  },
});

export const { setSocket } = SocketSlice.actions;
export const socketReducer = SocketSlice.reducer;
