
export interface RecommendationInterface {
    barrier: string;
    description: string;
    solutions: string[];
    
}

export const recommendation:RecommendationInterface[] = [
  {
    barrier: "Nonnegotiable problem",
    description:
      "Feelings, beliefs and viewpoints – are nonnegotiable: “I wish my wife to love me more”; “Everyone should believe in God”; “He is the best President”.",
    solutions: [
      "Ask questions that can lead to practical options. These questions start with “what” and “how”:  “How could you improve your relationship?”",
      "Focus on Interests and Needs - “Both of you wish to improve your marriage. Let us discuss how”.",
    ],
  },
  {barrier:'The fixed pie fallacy',
  description:'Parties believe that they are fighting over a fixed – well defined - object (such as property).',
  solutions:[
    'The pie – your assets - is not fixed. In property disputes – “you can decrease the value of your assets by going to court and pay high litigation fees.” ',
    'Add dimensions – “the dispute over the division of your property affects your kids. Please, take into consideration their wellbeing and find solution.”  '
  ]
  },
  {barrier:'Arguing about the past',
    description:'Parties tend to rewrite the past. Historical debate can lead to a blame game with disastrous effects.',
    solutions:[
        'Explain the problem: “You cannot start the next chapter of your life if you keep re-reading the last one.”',
        'Encourage pragmatism: “How can we solve our joint problem?”',
        'Remind their commitment to avoid historical disputes.'
    ]
  },
  {barrier:'Provocations',
    description:'An attitude of negotiator is offensive and create destructive chain reaction.  ',
    solutions:[
        'Send a message to the offensive party: “Please, show respect. A drop of honey catches more flies than tons of poison.”'
    ]
  },
  {barrier:'Lack of Trust ',
    description:'Each party does not trust the ability and willingness of the other to reach, implement and keep agreements.',
    solutions:[
        'Build "working trust"- focus on self-interests and needs: “Why do you need to solve the problem?”'
    ]
  },
  {barrier:'Search for Justice ',
    description:'Parties tend to view the conflict differently. Searching for the ultimate / historical justice can lead to a dead end. ',
    solutions:[
        'Search for ‘practical justice’ – agreements that address the interests, needs and fears of the parties on a basis of reciprocity: “How can we meet your needs?”',
    ]
  },
  {barrier:'Automatic Negative Reactions',
    description:'Each proposal of one side is automatically rejected by the other.',
    solutions:[
        'Use shuttle negotiation to discuss creative proposals.',
       'The mediator rephrase ideas and give the impression that he proposed them – “A possible solution is to reach two agreements. What is your opinion?”',

    ]
  },
  {barrier:' Internal sabotage',
    description:'Parties operate against their best interests and sabotage the negotiation.',
    solutions:[
        'Use shuttle negotiation to identify psychological incentives.',
        'Create value - “You need to reach understandings for the benefit of the company”.',
        'Emphasize losses – “Dealbreaker means disaster for both of you and your families.”',
    ]
  },
  {barrier:'The wrong players.',
    description:'Negotiating parties are not authorized to make decisions.',
    solutions:[
        'Change the topics and goals of the negotiation.',
        'Use the parties as messengers to the decision-making authorities.',
    ]
  },
  {barrier:'Hidden motivation',
    description:'Negotiating parties have hidden incentives to block possibilities to reach agreements.',
    solutions:[
        '. Use shuttle negotiations to gather information.',
        'Emphasize joint interests to reach agreements.',
        'Change the negotiating problem. '
    ]
  }
  
];
