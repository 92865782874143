import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { LoginData } from "../../types/userTypes";


export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `/api`,
    prepareHeaders: (headers, { getState }: { getState: () => any }) => {
      const access = getState().user.access;
      if (access) headers.set("Authorization", `JWT ${access}`);
      return headers;
    },
  }),
  endpoints(builder) {
    return {
      login: builder.mutation({
        query: (data: LoginData) => {
          return {
            url: "/core/auth/token/",
            method: "POST",
            credentials: "include",
            body: data,
          };
        },
      }),
      getMe: builder.query({
        query: () => {
          return {
            url: "/auth/users/me/",
            method: "GET",
          };
        },
      }),
      getAccessFromRefresh: builder.query({
        query: () => {
          return {
            url: "/core/auth/token/refresh/",
            method: "POST",
            credentials: "include",
          };
        },
      }),
      verifyAccess: builder.query({
        query: (access: string) => {
          return {
            url: "/auth/jwt/verify",
            method: "POST",
            body: {
              token:access,
            },
          };
        },
      }),
      getUserById: builder.query({
        query: (id: string) => {
          return {
            url: `/users/user/${id}/`,
            method: "GET",
          };
        },
      }),
    };
  },
});

export const {
  useLoginMutation,
  useLazyGetMeQuery,
  useGetMeQuery,
  useGetAccessFromRefreshQuery,
  useLazyGetAccessFromRefreshQuery,
  useLazyVerifyAccessQuery,
  useLazyGetUserByIdQuery,
  useGetUserByIdQuery,
  useVerifyAccessQuery,
} = userApi;
