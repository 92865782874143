import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

type clientData = {
  email: string;
  firstName: string;
  lastName: string;
};

type returnedClient = {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  phone?: string;
};

export const clientApi = createApi({
  reducerPath: "clientApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "/api",
  }),
  endpoints(builder) {
    return {
      getClient: builder.query<returnedClient, string>({
        query: (email: string) => {
          return {
            url: "/users/client/get_by_email/",
            method: "GET",
            params: { email },
          };
        },
      }),
      createClient: builder.mutation({
        query: (data: clientData) => {
          return {
            url: "/users/client/",
            method: "POST",
            body: {
              first_name: data.firstName,
              last_name: data.lastName,
              email: data.email,
            },
          };
        },
      }),
      updateClient: builder.mutation({
        query: ({ id, ...data }) => ({
          url: `/users/client/${id}/`,
          method: 'PATCH',
          body: {
            id:data.id,
            first_name: data.firstName,
            last_name: data.lastName,
            email: data.email,
          },
        }),
      }),
    };
  },
});

export const {
  useGetClientQuery,
  useLazyGetClientQuery,
  useCreateClientMutation,
  useUpdateClientMutation,
} = clientApi;
