import { useState, useEffect } from 'react';

// Define the mobile breakpoint
const MOBILE_BREAKPOINT = 768;

const useMobile = (): boolean => {
  // State to store whether the user is on a mobile device or not
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < MOBILE_BREAKPOINT);

  useEffect(() => {
    // Function to handle window resize and update the state
    const handleResize = () => {
      setIsMobile(window.innerWidth < MOBILE_BREAKPOINT);
    };

    // Add event listener on window resize
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return isMobile;
};

export default useMobile;
