import { Button } from "@/components/ui/button";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@radix-ui/react-popover";
import "./dropdown.css";

interface PopOverContent {
  trigger: React.ReactNode;
  children: React.ReactNode;
  className?: string;
}

const PopOver = (props: PopOverContent) => {
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button className="w-fit h-fit bg-transparent hover:bg-transparent">
          {props.trigger}{" "}
        </Button>
      </PopoverTrigger>
      <PopoverContent
        side="left"
        align="start"
        className={`w-fit h-fit animation-open-left-to-right z-10 ${props.className}`}
      >
        {props.children}
      </PopoverContent>
    </Popover>
  );
};

export default PopOver;
