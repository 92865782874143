import { Textarea } from "@/components/ui/textarea";
import { ContinueButtons } from './GuidedUtils'

const GreetingsStage = (props: {
  handleChangeStage: (idx: number) => void;
  message: string;
  setMessage: (msg: string) => void;
}) => {


  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    props.setMessage(e.target.value);
  };

  return (
    <div className=" h-full w-full text-white text-center flex flex-col items-center gap-10 justify-start   ">
      <h3 className="">
        Greetings & introduction
        <br />
        <span className="text-zinc-300 text-sm">
          Please provide a greeting message to the parties
        </span>
      </h3>

      <div className="w-full h-72 flex flex-col gap-2">
        <Textarea
          value={props.message}
          onChange={handleChange}
          className="bg-zinc-950 text-lg font-extralight  flex-grow"
          placeholder="Type your message here"
        />
        <ContinueButtons
          initIndex={1}
          handleChangeStage={props.handleChangeStage}
        />
      </div>
    </div>
  );
};
export default GreetingsStage;
