import { Participant } from "types/userTypes";
import { participantColors, participantIcons } from '../infoSection/infoSectionData';

interface ParticipantViewProps {
    participants: Map<string, Participant>;

}

const ParticipantView = (props:ParticipantViewProps) => {
  return (
    <>
      <h3 className=" text-white text-lg font-mono font-extralight text-center">
        Participant
      </h3>

      <div className="flex-grow overflow-x-hidden border-2 divide-y-2 divide-slate-400  border-gray-900 overflow-y-scroll">
        {Array.from(props.participants).map(([key, value]) => {
          return (
            <div key={key} className="flex justify-between items-center">
              <div className="w-full h-16 bg-slate-900 flex justify-end xl:justify-center items-center relative overflow-hidden">
                <div
                  className={`absolute  md:top-[-1.5rem] left-[-1.5rem] w-20 h-20   md:w-24 md:h-24 ${
                    participantColors[
                      value.side as keyof typeof participantColors
                    ]
                  } rounded-full`}
                >
                  {
                    participantIcons[
                      value.side as keyof typeof participantIcons
                    ]
                  }
                </div>
                <p className="text-white text-sm xl:text-3xl mr-2 font-mono">
                  {value.displayName}
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};
export default ParticipantView;
