import { GroupType } from "types/messageType";
import InputBar from "../InputBar/inputBarNew";
import MessagesBoard from "./MessageBoard";
import { Participant } from "types/userTypes";
import { ImNotification } from "react-icons/im";
import { Button } from "@/components/ui/button";
import { HoverCard, HoverCardTrigger } from "@radix-ui/react-hover-card";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";
import InfoBar from "../InfoBar/infoBar";
import { useAppDispatch, useAppSelector } from "hooks/storHook";
import { Separator } from "@/components/ui/separator";
import { Input } from "@/components/ui/input";
import { removeNotification } from "store";

interface ChatSectionProps {
  role: string;
  handleSendMessage: (msg: string) => void;
  selectedGroup: GroupType;
  isShuttle: boolean;
  handleShuttle: () => void;
  participants: Map<string, Participant>;
  side: "A" | "B" | "M";
  case_category: string;
}

const ChatSection = (props: ChatSectionProps) => {
  const [scrollYProgress, setScrollYProgress] = useState<number>(0);

  const handleScroll = (progress: number) => {
    setScrollYProgress(progress);
  };

  return (
    <div className="divide-y flex flex-col  overflow-hidden   divide-stone-600 w-full h-full    ">
      <header className="w-full h-16 flex  bg-slate-900  overflow-y-hidden">
        <InfoBar
          isShuttle={props.isShuttle}
          side={props.side}
          case_category={props.case_category}
        />
        <NotificationViewer isShuttle={props.isShuttle} />
      </header>

      <section className="w-full h-full  overflow-hidden  ">
        <MessagesBoard
          handleScroll={handleScroll}
          participants={props.participants}
          selectedGroup={props.selectedGroup}
        />
      </section>

      <motion.div
        className="progress bg-gradient-to-r from-emerald-300 to-emerald-500 w-full h-2"
        style={{ scaleX: scrollYProgress }}
      />

      <footer className="w-full h-20 flex py-2 pl-1 pr-2 items-center justify-center">
        <InputBar
          role={props.role}
          handleShuttle={props.handleShuttle}
          isShuttle={props.isShuttle}
          handleSendMessage={props.handleSendMessage}
          selectedGroup={props.selectedGroup}
        />
      </footer>
    </div>
  );
};

export default ChatSection;

const NotificationViewer = ({ isShuttle }: { isShuttle: boolean }) => {
  const notification = useAppSelector((state) => state.notification);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!notification.length) return;
    setTimeout(() => {
      dispatch(removeNotification());
    }, 3000);
  }, [notification]);

  return (
    <AnimatePresence mode="wait">
      {!isShuttle && !notification.length ? (
        <></>
      ) : notification[0] ? (
        <motion.div
          key={11}
          initial={{ y: "100%", opacity: 0 }}
          animate={{ y: "0%", opacity: 0.6 }}
          exit={{ y: "100%", opacity: 0 }}
          className="w-full mt-2 px-5 flex justify-center   items-center bg-black "
        >
          <p className="text-white">{notification[0]}</p>
        </motion.div>
      ) : (
        <motion.div
          key={12}
          initial={{ y: "100%", opacity: 0 }}
          animate={{ y: "0%", opacity: 0.6 }}
          exit={{ y: "100%", opacity: 0 }}
          className="w-full mt-2 px-5 flex justify-center   items-center bg-black "
        >
          <p className="text-white">Shuttle mode activated</p>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
