import { LightHeadline } from "../../components/background/headline";
import SideContent from "../../components/sideBar/sideContent";
import ChatSection from "../../components/chat/chatSection";
import { MessageType, GroupType } from "types/messageType";
import { Participant } from "types/userTypes";
import { CaseReturnType } from "types/CaseTypes";
import useMobile from "hooks/useMobile";
import InfoSectionNew from "../../components/infoSection/InfoSectionNew";
import { Drawer, DrawerContent, DrawerTrigger } from "@/components/ui/drawer";
import { RiMenuUnfold3Line2 } from "react-icons/ri";
import { AlertDialogView } from "../../components/alert/AlertDialog";

interface ChatViewProps {
  role: string;
  isShuttle: boolean;
  case_id: string;
  user: any;
  messages: MessageType[];
  handleSendMessage: (message: string) => void;
  onChangeGroup: (group: GroupType) => void;
  selectedGroup: GroupType;
  handleShuttle: () => void;
  participants: Map<string, Participant>;
  caseInfo?: CaseReturnType;
  handleSelectInfo: (idx: number) => void;
  selectedInfo: number;
  openedDialog?: boolean;
  onDialogClose: (accept:boolean) => void;
}

const ChatViewNew = (props: ChatViewProps) => {
  const isMobile = useMobile();

  return (
    <div
      className={`bg-slate-900 w-full h-[100dvh] overflow-hidden ${
        isMobile && "relative"
      } flex justify-between py-2 pr-2 gap-2 px-1`}
    >
      <LightHeadline />
      <AlertDialogView
        withOutCancel
        title="Mediator Close this case"
        description="You will be logged out after closing this case"
        isOpen={props.openedDialog}
        onClose={props.onDialogClose}
      />

      <section className="h-full  sm:border-r-[0.5px]   sm:border-white w-13 gap-0   sm:w-20 flex shrink-0  flex-col">
        {isMobile && (
          <Drawer direction="left">
            <DrawerTrigger className="w-full z-10 flex justify-center items-center bg-zinc-900 h-10 border-[0.5px] border-white  ">
              <RiMenuUnfold3Line2 className="w-6 h-6 z-10 text-white" />
            </DrawerTrigger>
            <DrawerContent className="h-full w-full flex">
              <div className="relative w-full h-[100dvh]">
                <InfoSectionNew
                  selectedInfoIdx={props.selectedInfo}
                  role={props.role}
                  participants={props.participants}
                  caseInfo={props.caseInfo}
                  handleSelectInfo={props.handleSelectInfo}
                />
              </div>
            </DrawerContent>
          </Drawer>
        )}

        <SideContent
          selectedInfo={props.selectedInfo}
          handleSelectInfo={props.handleSelectInfo}
          selectedGroup={props.selectedGroup}
          handleChangeGroup={props.onChangeGroup}
        />
      </section>
      {!isMobile && (
        <section className="z-2 min-w-64 max-w-64 lg:min-w-[30rem] lg:max-w-[30rem] ">
          <InfoSectionNew
            selectedInfoIdx={props.selectedInfo}
            role={props.role}
            participants={props.participants}
            caseInfo={props.caseInfo}
            handleSelectInfo={props.handleSelectInfo}
          />
        </section>
      )}

      <section
        style={{
          width: isMobile ? "calc(100% - 4rem)" : "auto",
        }}
        className="flex-grow sm:max-w-[60%]  2xl:max-w-[75%] h-full bg-[#414a4f] "
      >
        <ChatSection
          case_category={props.caseInfo?.category || ""}
          role={props.role}
          side={props.user.side as "A" | "B" | "M" | "M"}
          handleShuttle={props.handleShuttle}
          isShuttle={props.isShuttle}
          handleSendMessage={props.handleSendMessage}
          selectedGroup={props.selectedGroup}
          participants={props.participants}
        />
      </section>
    </div>
  );
};
export default ChatViewNew;
