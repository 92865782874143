import FormInput from "../../components/input/formInput";
import Headline from "../../components/background/headline";
import CostumeDropDown from "../../components/dropdown/costumeDropDown";
import PopButton from "../../components/buttons/PopButton";
import { useRef, useState } from "react";
import { useCreateCaseMutation } from "store/apis/caseApi";
import { useAppSelector } from "hooks/storHook";
import { Categories, PostCaseType } from "types/CaseTypes";
import { useNavigate } from "react-router-dom";
interface CaseFormData {
  title: string;
  category: string;
  subCategory: string;
  problemBrief: string;
  sideA_description?: string;
  sideB_description?: string;
}

const AddCaseView = () => {
  const [formData, setFormData] = useState<CaseFormData>({
    title: "",
    category: "",
    subCategory: "",
    problemBrief: "",
  });
  const user = useAppSelector((state) => state.user);
  const categoryOptions = [...Object.keys(Categories)];
  const [createNewCase, { isLoading }] = useCreateCaseMutation();
  const navigator = useNavigate();

  const statusRefs: { [key: string]: React.RefObject<HTMLInputElement> } = {
    title: useRef<HTMLInputElement>(null),
    category: useRef<HTMLInputElement>(null),
  };

  const handleChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    if (statusRefs[name]) statusRefs[name].current!.innerText = "";

    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = () => {
    const { title, category, subCategory, problemBrief } = formData;
    if (statusRefs.title.current && statusRefs.category.current) {
      if (!title) statusRefs.title.current.innerText = "Title is required";
      if (!category)
        statusRefs.category.current.innerText = "Category must be selected";
      if (!title || !category) return;
    }

    const caseData: PostCaseType = {
      title,
      sub_category: subCategory,
      problem_brief: problemBrief,
      category: Categories[category as keyof typeof Categories],
      mediator: user.id,
    };
    createNewCase(caseData).then((res) => {
      if (res.error) {
        //add notification for error in creating case
      } else {
        //add success notification
        navigator("/Mediator/dashboard");
      }
    });
  };

  return (
    <div className="w-full min-h-[100dvh] bg-[#414a4f] px-4 py-12 text-slate-50 flex flex-col ">
      <Headline text="Add Case" />

      <form onSubmit={(e) => e.preventDefault()}>
        <center className="flex flex-col gap-5 justify-center items-center">
          <FormInput
            statusRef={statusRefs.title}
            name="title"
            placeHolder="Case Title"
            handelChange={handleChange}
            rightLabel="Require"
            type="text"
          />
          <CostumeDropDown
            statusRef={statusRefs.category}
            initialValue="Please select a category"
            handleChange={handleChange}
            name="category"
            options={categoryOptions}
          />
          <FormInput
            name="subCategory"
            placeHolder="Sub Category"
            handelChange={handleChange}
            type="text"
          />

          <textarea
            onChange={handleChange}
            name="problemBrief"
            id="problem-brief"
            className=" textarea-lg bg-[#1D232A] mt-10 "
            placeholder="Problem brief"
          />
          <FormInput
            leftLabel="description of the side in the conflict"
            name="sideA_description"
            placeHolder="side A description"
            handelChange={handleChange}
            type="text"
          />
          <FormInput
            leftLabel="description of the side in the conflict"
            name="sideB_description"
            placeHolder="side B description"
            handelChange={handleChange}
            type="text"
          />

          <PopButton
            loading={isLoading}
            text="Submit"
            handleClick={handleSubmit}
          />
        </center>
        {/* <label htmlFor="problem-brief" className="form-control w-full max-w-xs text-sm font-thin mt-10 "> Problem brief</label> */}
      </form>
    </div>
  );
};
export default AddCaseView;
