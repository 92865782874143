import PopButton from '../../buttons/PopButton';

const StartStage = (props: { handleChangeStage: (idx: number) => void }) => {
  return (
    <div className="text-center flex  flex-col items-center gap-10 justify-center mt-10">
      <h3 className="text-white">
        Guided negotiation will add features to the chat and add context to it{" "}
        to start please click the start button
      </h3>
      <PopButton
        text="Start Guided Negotiation"
        handleClick={() => props.handleChangeStage(1)}
      />
    </div>
  );
};
export default StartStage;
