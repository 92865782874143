import { Participant } from "./userTypes";

export enum Categories {
  FAMILY = "Family",
  FRIENDS = "Friends",
  NEIGHBORS = "Neighbors",
  EDUCATION = "Education",
  ORGANIZATIONS = "Organization",
  BUSINESS = "Business",
  POLITICS = "Politics",
  OTHER = "Other",
  NONE = "None",
}
export type CaseSaveType = {
  link: string;
  case_id: string;
  case_title: string;
  shuttle: boolean;
  category?: Categories;
  problem_brief?: string;
  sideA_description?: string;
  sideB_description?: string;
  sub_category?: string;
  participants: Participant[];
};

export type CaseType = {
  mediator: number;
  title: string;
  category: Categories;
  sub_category?: string;
  problem_brief?: string;
  close_at?: string;
  is_active: boolean;
  create_at: string;
};

export type PostCaseType = {
  mediator: number;
  title: string;
  category: Categories;
  sub_category?: string;
  problem_brief?: string;
};

export interface CaseReturnType extends PostCaseType {
  id?: string;
  close_at?: string;
  is_active?: boolean;
  summary?: string;
  sideA_description?: string;
  sideB_description?: string;
}

export enum Emits {
  startCase = "startCase",
  roomMsg = "roomMsg",
  endCase = "endCase",
  shuttle = "shuttle",
  connection = "connection",
  history = "history",
  userJoin = "userJoin",
  userLeave = "userLeave",
  action = "action",
  guid = "guid",
  historyActions = "historyActions",
  endGuid = "endGuid",
  note = "note",
  like = "like",
  removeLike = "removeLike",
  editMsg = "editMsg",
}

export type CaseDataRedis = {
  id: string;
  creator: string;
  shuttle: boolean;
};
export type guidType = {
  case_id: string;
  stageIdx: number;
  initialIteration: number;
  iteration: number;
  receivedInitialA: string;
  receivedInitialB: string;
};

export enum ActionPayloads {
  initialPosition = "initialPosition",
  receivedInitialA = "receivedInitialA",
  receivedInitialB = "receivedInitialB",
}

export enum ActionTypes {
  add = "add",
  remove = "remove",
}

export type ActionMsg = {
  displayName: string;
  from: number;
  to: string;
  actionPayload: ActionPayloads;
  actionType: ActionTypes;
  side: string;
  time: Date;
  extMsg?: string;
};
