import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Define TypeScript Types
type LikeData = {
  user_id: string;
  displayName: string;
  like: string;
};

type LikeType = { A: LikeData[]; B: LikeData[]; M: LikeData[] };

type LikeReturnType = {
  [message_id: string]: {
    isMe: boolean;
    likeData: LikeType;
  };
};

// Define Initial State
const initialState: LikeReturnType = {};

// Define the Slice
const likeSlice = createSlice({
  name: "like",
  initialState,
  reducers: {
    removeLike: (
      state,
      action: PayloadAction<{
        message_id: string;
        user_id: string;
        side: string;
        isMe: boolean; // Simply tells if the current user did the action
      }>
    ) => {
      const { message_id, user_id, side, isMe } = action.payload;

      // Check if the message_id exists in the state
      if (state[message_id]) {
        // Remove the like from the specified side array
        const newState = state[message_id].likeData[
          side as keyof LikeType
        ].filter((like) => like.user_id !== user_id.toString());

        // If this is the current user's action, set isMe to false
        if (isMe) {
          state[message_id].isMe = false;
        }

        state[message_id].likeData[side as keyof LikeType] = newState;
      }
    },
    addLikes: (
      state,
      action: PayloadAction<
        {
          message_id: string;
          side: string;
          displayName: string;
          user_id: string;
          like: string;
          isMe: boolean;
        }[]
      >
    ) => {
      // Temporary object to collect all new likes
      const newLikes: LikeReturnType = {};

      // Process each like in the payload
      action.payload.forEach((like) => {
        const {
          message_id,
          side,
          user_id,
          displayName,
          like: likeValue,
          isMe,
        } = like;

        // Initialize message_id in newLikes if not already present
        if (!newLikes[message_id]) {
          newLikes[message_id] = {
            isMe: false,
            likeData: { A: [], B: [], M: [] },
          };
        }

        // Update the isMe flag if this like is from the current user
        if (isMe) {
          newLikes[message_id].isMe = true;
        }

        // Create the likeData object
        const likeData: LikeData = {
          user_id,
          displayName,
          like: likeValue,
        };

        // Add likeData to the appropriate side group (A, B, or M)
        newLikes[message_id].likeData[side as keyof LikeType].push(likeData);
      });

      // Merge newLikes into the current state
      return {
        ...state,
        ...Object.keys(newLikes).reduce((acc, messageId) => {
          // Check if the messageId exists in the state
          if (state[messageId]) {
            acc[messageId] = {
              isMe: newLikes[messageId].isMe || state[messageId].isMe,
              likeData: {
                A: [
                  ...state[messageId].likeData.A,
                  ...newLikes[messageId].likeData.A,
                ],
                B: [
                  ...state[messageId].likeData.B,
                  ...newLikes[messageId].likeData.B,
                ],
                M: [
                  ...state[messageId].likeData.M,
                  ...newLikes[messageId].likeData.M,
                ],
              },
            };
          } else {
            // If not, just add the new likes directly
            acc[messageId] = newLikes[messageId];
          }
          return acc;
        }, {} as LikeReturnType),
      };
    },
    clearLikes: () => {
      return {};
    },
  },
});

// Export Actions and Reducer
export const { addLikes, clearLikes, removeLike } = likeSlice.actions;
export const likeReducer = likeSlice.reducer;
