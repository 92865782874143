import { motion } from "framer-motion";
import { useState } from "react";
import { FaChevronRight } from "react-icons/fa";

const LinkMenu = ({
  text,
  onClick,
}: {
  text: string[];
  onClick: (idx: number) => void;
}) => {
  const [hoverIdx, setHoverIdx] = useState<number>(0);
  return (
    <ul className="text-black mx-auto w-full cursor-pointer  mt-12 max-w-3xl">
      {text.map((t, idx) => (
        <motion.li
          initial={{ x: -50, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.5, delay: idx * 0.1 }}
          onMouseEnter={() => setHoverIdx(idx)}
          key={idx}
          onClick={() => onClick(idx)}
          className=" w-full mb-5 flex justify-between  hover:!-translate-x-3 px-6 hover:!backdrop-brightness-125 active:!backdrop-brightness-150 hover:!scale-y-110 hover:!-skew-y-1 hover:!shadow-2xl transition-all duration-300 ease-in-out "
        >
          <div className="h-12 font-light text-3xl">{t}</div>
          {hoverIdx === idx && (
            <motion.div
              initial={{ x: -50, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              exit={{ x: -50, opacity: 0 }}
              className="h-12 w-12 flex justify-center items-center "
            >
              <FaChevronRight className="text-3xl   w-8 h-8" />
            </motion.div>
          )}
        </motion.li>
      ))}
    </ul>
  );
};
export default LinkMenu;
