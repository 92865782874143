import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";
import { IoMdTrash } from "react-icons/io";
import { TbLetterASmall, TbLetterBSmall } from "react-icons/tb";
import { FaChevronRight } from "react-icons/fa";
import { ToggleGroup, ToggleGroupItem } from "@/components/ui/toggle-group";
import { useAppSelector } from "hooks/storHook";
import { useDispatch } from "react-redux";
import { setNotes } from "store";
import { useSocket } from "hooks/useSocket";
import { Emits } from "types/CaseTypes";

const Note = () => {
  const [newLine, setNewLine] = useState<string>("");
  const [tag, setTag] = useState<"A" | "B" | "Both">("Both");
  const { notes } = useAppSelector((state) => state.guid);
  const { socket } = useSocket();
  const dispatch = useDispatch();
  const { case_id } = useAppSelector((state) => state.case);

  const handleAddNote = () => {
    if (!newLine) return;
    dispatch(setNotes([...notes, { note: newLine, groupTag: tag }]));
    setTag((prev) => "Both");
    setNewLine("");
    socket?.emit(Emits.note, {
      case_id: case_id,
      note: { note: newLine, groupTag: tag },
      action: "add",
    });
  };

  const handleRemoveNote = (idx: number) => {
    dispatch(setNotes(notes.filter((_, index) => index !== idx)));
    socket?.emit(Emits.note, {
      case_id: case_id,
      idx: idx,
      action: "remove",
    });
  };

  const handleAddTag = (newTag: "A" | "B" | "Both") => {
    const currentTag = tag;
    if (currentTag === newTag) {
      setTag("Both");
    } else {
      setTag(newTag);
    }
  };
  return (
    <motion.header
      initial={{ x: "-10%", opacity: 0 }}
      animate={{ x: "0%", opacity: 1 }}
      exit={{ x: "10%" }}
      transition={{
        duration: 0.3,
        delay: 0.3,
        ease: "backOut",
      }}
      className="px-2 flex flex-col relative overflow-x-hidden text-white"
    >
      <h3 className="text-white text-lg font-mono font-extralight text-center mb-10 ">
        Note
      </h3>
      <h3 className="text-white text-center text-lg mb-10">
        Here you can write notes about the negotiation <br />{" "}
        <p className="text-slate-300 text-sm">
          This can help you to summaries and be focus{" "}
        </p>
      </h3>
      <ol className="list-decimal list-inside font-thin">
        <AnimatePresence mode="sync">
          {notes?.map?.((note, idx) => (
            <motion.li
              initial={{ x: "-10%", opacity: 0 }}
              animate={{ x: "0%", opacity: 1 }}
              exit={{ x: "-100%", opacity: 0 }}
              key={idx}
              className="bg-yellow-200 p-4 rounded-lg shadow-lg text-black w-full my-4  transform  relative"
            >
              {note.note}
              <div className="flex gap-2 absolute top-1 items-center right-1 text-white h-full">
                {note.groupTag === "A" ? (
                  <TbLetterASmall className="w-full h-full text-teal-500 drop-shadow-lg" />
                ) : (
                  note.groupTag === "B" && (
                    <TbLetterBSmall className="w-full h-full text-rose-500 drop-shadow-lg" />
                  )
                )}
                <Button
                  variant="outline"
                  className="p-1 aspect-square bg-transparent border-black text-slate-500 hover:bg-rose-300"
                >
                  <IoMdTrash
                    className="w-6 h-full cursor-pointer "
                    onClick={() => handleRemoveNote(idx)}
                  />
                </Button>
              </div>
            </motion.li>
          ))}
        </AnimatePresence>
      </ol>

      <Textarea
        value={newLine}
        onChange={(e) => setNewLine(e.target.value)}
        placeholder="Write your note here"
        className="bg-transparent border-none outline-none  focus-visible:ring-0 focus-visible:ring-offset-0 h-fit text-lg font-thin rounded-none resize-none"
      />

      <div id="note-buttons-action" className="flex gap-10">
        <Button
          variant="outline"
          className="w-fit h-fit p-2 bg-transparent  active:scale-90 transition-all duration-150 "
          onClick={handleAddNote}
        >
          <p>ADD NOTE </p>
          <FaChevronRight className="w-5 h-5 text-emerald-400 " />
        </Button>
        <p>
          Add Tags: <br />
          <span className="text-slate-400 text-xs"> Default: both</span>
        </p>
        <ToggleGroup type="single">
          <ToggleGroupItem
            data-state={tag === "A" ? "on" : "off"}
            onClick={() => handleAddTag("A")}
            value="A"
            aria-label="Toggle bold"
            className="aspect-square p-0 ToggleGroupItemA "
          >
            <TbLetterASmall className="h-full w-full" />
          </ToggleGroupItem>
          <ToggleGroupItem
            data-state={tag === "B" ? "on" : "off"}
            onClick={() => handleAddTag("B")}
            value="B"
            aria-label="Toggle bold"
            className="aspect-square p-0 ToggleGroupItemB"
          >
            <TbLetterBSmall className="h-full w-full" />
          </ToggleGroupItem>
        </ToggleGroup>
      </div>
    </motion.header>
  );
};
export default Note;
