import "./input.css";
import { REGEXP_ONLY_DIGITS_AND_CHARS } from "input-otp";
import { useEffect, useRef, useState } from "react";
import { CircleX, ThumbsUp } from "lucide-react";
import { useLocation } from "react-router-dom";
import { useLazyLoginUserQuery } from "store/apis/caseApi";
import FormInput from "./formInput";
import {
  InputOTP,
  InputOTPGroup,
  InputOTPSlot,
} from "@/components/ui/input-otp";
import { motion, AnimatePresence } from "framer-motion";
import PopButton from "../../components/buttons/PopButton";
import { useUpdateClientMutation } from "store/apis/clientApi";
import {
  useLazyGetClientQuery,
  useCreateClientMutation,
} from "store/apis/clientApi";
import { useLazyGetCaseByIdQuery } from "store/apis/caseApi";
import { CaseReturnType } from "types/CaseTypes";
import { useAppDispatch } from "hooks/storHook";
import { setCase } from "store";

enum InputState {
  NONE,
  IDLE,
  ERROR,
  SUCCESS,
  EMAIL,
  SIDE,
  NAME,
}

type formDataType = {
  email: string;
  firstName: string;
  lastName: string;
  id: number;
  case_id: string;
  side: string;
};

interface FormData {
  handleStart: (data: formDataType) => void;
}

const RegExp = ({ handleStart }: FormData) => {
  const SLOTS_NUMBER = 6;
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const code = query.get("case_code") || "";
  const [loginUser, { isFetching, error, data, status }] =
    useLazyLoginUserQuery();
  const [value, setValue] = useState<string>(code);
  const [state, setState] = useState<InputState>(InputState.NONE);
  const [getClient, { isLoading: loadingData }] = useLazyGetClientQuery();
  const checkChangingRef = useRef<boolean>(false);
  const [updateClient] = useUpdateClientMutation();
  const [getCaseData] = useLazyGetCaseByIdQuery();
  const [caseData, setCaseData] = useState<CaseReturnType | null>(null);
  const dispatch = useAppDispatch();
  const [createClient, { isLoading: loadingCreate }] =
    useCreateClientMutation();
  const [formData, setFormData] = useState<formDataType>({
    email: "",
    firstName: "",
    lastName: "",
    id: -1,
    case_id: "",
    side: "",
  });

  const handleSubmitCaseCode = (value: string) => {
    loginUser(value).then((res) => {
      if (!res.data || !res.data.case_id) return;
      if (res.status === "rejected") return;

      getCaseData(res.data.case_id).then((res) => {
        if (!res.data || res.error) return;

        setCaseData(res.data);
        setState(InputState.SIDE);
        dispatch(setCase({ case_id: res.data.id, case_title: res.data.title }));
        if (!res.data.id) return;
        localStorage.setItem("case_id", res.data.id);
      });

      setFormData((prev) => ({
        ...prev,
        case_id: res.data.case_id,
      }));
    });
  };

  useEffect(() => {
    if (isFetching) setState(InputState.IDLE);
    if (error) setState(InputState.ERROR);
    if (status === "rejected") {
      setState(InputState.ERROR);
      return;
    }

    if (data) {
      setState(InputState.SUCCESS);
    }
  }, [isFetching]);

  useEffect(() => {
    if (value.length < SLOTS_NUMBER) return;
    handleSubmitCaseCode(value);
  }, [value]);

  const handleSubmitEmail = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (formData.email === "") return;

    getClient(formData.email)
      .then((res) => {
        if (res.data) {
          const { first_name, last_name, id } = res.data;
          setFormData((prev) => ({
            ...prev,
            firstName: first_name,
            lastName: last_name,
            id,
          }));
        }

        setState(InputState.NAME);
      })
      .catch((err) => console.log(err));

    setState(InputState.NAME);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    if (
      (name === "firstName" || name === "lastName") &&
      checkChangingRef.current === false
    ) {
      checkChangingRef.current = true;
    }
  };

  const handleSubmitFullName = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    let userData = { ...formData };
    if (
      formData.email === "" ||
      formData.firstName === "" ||
      formData.lastName === ""
    )
      return;

    if (formData.id === -1) {
      const newUserData = await createClient(formData);
      if (newUserData.data) userData = { ...userData, ...newUserData.data };
    } else if (checkChangingRef.current) {
      const updatedUserData = await updateClient({
        ...formData,
        id: formData.id,
      });
      if (updatedUserData.data)
        userData = { ...userData, ...updatedUserData.data };
    }
    handleStart({ ...userData });
  };

  const handleSelectSide = (side: string) => {
    setFormData((prev) => ({
      ...prev,
      side,
    }));
    setState(InputState.EMAIL);
  };

  return (
    <div className="flex w-full items-center relative gap-5">
      <AnimatePresence>
        {state !== InputState.EMAIL &&
          state !== InputState.NAME &&
          state !== InputState.SIDE && (
            <motion.div
              initial={{ translateY: 100, opacity: 0 }}
              animate={{ translateY: 0, opacity: 1 }}
              transition={{ duration: 0.3 }}
              exit={{
                translateY: 100,
                opacity: 0,
                transition: { duration: 0.5, delay: 1 },
              }}
            >
              <InputOTP
                inputMode="text"
                autoFocus={true}
                disabled={state === InputState.IDLE}
                onChange={(e) => setValue(e)}
                maxLength={6}
                value={value}
                pattern={REGEXP_ONLY_DIGITS_AND_CHARS}
              >
                <InputOTPGroup>
                  {[...Array(SLOTS_NUMBER)].map((_, index) => (
                    <InputOTPSlot
                      //keep it focused automaticllly when appear

                      key={index}
                      className={`${
                        value.length > index && "bg-emerald-500"
                      } border-black`}
                      index={index}
                    />
                  ))}
                </InputOTPGroup>
              </InputOTP>
              <p>Please enter the case code</p>
            </motion.div>
          )}
      </AnimatePresence>
      <AnimatePresence>
        {state === InputState.SIDE && (
          <motion.form
            onSubmit={handleSubmitEmail}
            initial={{ translateY: 100, opacity: 0, display: "none" }}
            animate={{ translateY: 0, opacity: 1, display: "block" }}
            transition={{ duration: 0.2, delay: 2.2 }}
            exit={{
              translateY: 100,
              opacity: 0,
              transition: { duration: 0.5, delay: 0.8 },
            }}
            className="z-50 "
          >
            <div className="w-96 flex justify-center flex-col  items-center">
              <h1 className=" font-serif text-2xl text-center">
                {caseData?.problem_brief}
              </h1>
              <h1 className="text-white text-center m-2 ">
                Please select the side you are representing
              </h1>
              <div className="flex justify-between gap-5">
                <button
                  onClick={() => handleSelectSide("A")}
                  className="btn text-primary-content btn-primary"
                >
                  {caseData?.sideA_description || "Side A"}
                </button>
                <button
                  onClick={() => handleSelectSide("B")}
                  className="btn text-secondary-content btn-secondary"
                >
                  {caseData?.sideB_description || "Side B"}
                </button>
              </div>
            </div>
          </motion.form>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {state === InputState.EMAIL && (
          <motion.form
            onSubmit={handleSubmitEmail}
            initial={{ translateY: 100, opacity: 0, display: "none" }}
            animate={{ translateY: 0, opacity: 1, display: "block" }}
            transition={{ duration: 0.2, delay: 2.2 }}
            exit={{
              translateY: 100,
              opacity: 0,
              transition: { duration: 0.5, delay: 0.8 },
            }}
            className="z-50 "
          >
            <div className="w-96 flex justify-center  items-end">
              <FormInput
                autoFocus={true}
                autoComplete="email"
                placeHolder="Email"
                type="email"
                handelChange={handleChange}
                name={"email"}
              />
              <div className="flex-grow-0 p-1">
                <PopButton text={"Submit"} />
              </div>
            </div>

            <h1 className="text-white text-center mt-2">
              Please enter your email
            </h1>
          </motion.form>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {state === InputState.NAME && (
          <motion.form
            onSubmit={handleSubmitFullName}
            initial={{ translateY: 100, opacity: 0, display: "none" }}
            animate={{ translateY: 0, opacity: 1, display: "block" }}
            transition={{ duration: 0.2, delay: 2.2 }}
            exit={{
              translateY: 100,
              opacity: 0,
              transition: { duration: 0.5, delay: 0.8 },
            }}
            className="z-50 "
          >
            <div className="w-96 flex flex-col justify-center  items-center">
              <FormInput
                value={formData.firstName}
                leftLabel="Please enter your name"
                placeHolder="First Name"
                handelChange={handleChange}
                name={"firstName"}
              />
              <FormInput
                value={formData.lastName}
                leftLabel="Please enter your name"
                placeHolder="Last Name"
                handelChange={handleChange}
                name={"lastName"}
              />
              <div className="flex-grow mt-2">
                <PopButton text={"Submit"} />
              </div>
            </div>
          </motion.form>
        )}
      </AnimatePresence>
      <div
        className={`w-16 h-16 absolute right-0 translate-x-[105%] ${
          state === InputState.IDLE && "flex items-end"
        }`}
      >
        {state === InputState.IDLE && (
          <span className="loading loading-dots loading-lg"></span>
        )}
        {state === InputState.ERROR && (
          <CircleX size={48} color="#fb7185" className="appearUp " />
        )}
        {state === InputState.SUCCESS && (
          <ThumbsUp size={48} color="#6ee7b7" className="appearUp" />
        )}
      </div>
    </div>
  );
};
export default RegExp;
