import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";

export function AlertDialogView({
  isOpen,
  onClose,
  title,
  description,
  withOutCancel,
}: {
  isOpen?: boolean;
  onClose: (accept: boolean) => void;
  title: string;
  description: string;
  withOutCancel?: boolean;
}) {
  return (
    <AlertDialog open={isOpen} onOpenChange={onClose}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>{title}</AlertDialogTitle>
          <AlertDialogDescription>{description}</AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          {!withOutCancel && (
            <AlertDialogCancel onClick={() => onClose(false)}>
              Cancel
            </AlertDialogCancel>
          )}

          <AlertDialogAction onClick={() => onClose(true)}>
            Continue
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
