import { DropDownBox } from "../../components/dropdown/dropDownBox";
import { Button } from "@/components/ui/button";
import { motion } from "framer-motion";
import { useState } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

interface MultiSelectOptionsProps {
  options: string[];
  selectedOptions: Set<string>;
  setSelectedOptions: (options: Set<string>) => void;
}

const MuliSelectOptions = (props: MultiSelectOptionsProps) => {
 
  const [openedSelection, setOpenedSelection] = useState(false);

  const handleAddOrDeleteOption = (option: string) => {
    const newOptions = new Set(props.selectedOptions);
    if (props.selectedOptions.has(option)) {
      newOptions.delete(option);
    } else {
      newOptions.add(option);
    }
    props.setSelectedOptions(newOptions);
  };

  return (
    <>
      <div className="w-[95%] bg-[#1D232A] relative h-16 pl-5 mt-4 overflow-scroll border-2 ">
        <div className="snap-y snap-mandatory flex flex-wrap ">
          {Array.from(props.selectedOptions).map((option) => {
            return (
              <motion.p
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.5, ease: "backInOut" }}
                key={option}
                className="text-start text-nowrap font-light text-white px-2 py-[1px] bg-gradient-to-tr to-indigo-500 to from-violet-500 my-1 rounded-sm snap-start mr-2 mb-2"
              >
                {option}
              </motion.p>
            );
          })}
          {props.selectedOptions.size === 0 && (
            <p className="text-slate-400">
              Please select an Area of meditation
            </p>
          )}
        </div>
        <Button
          variant="ghost"
          type="button"
          onClick={() => setOpenedSelection(!openedSelection)}
          className="absolute bottom-0 h-16 right-0 rounded-none hover:bg-slate-100"
        >
          {openedSelection ? (
            <FaChevronUp className="h-4 w-full" />
          ) : (
            <FaChevronDown className="h-4 w-full" />
          )}
        </Button>
      </div>
      <div className="relative -mt-16 w-[95%]  ">
        <DropDownBox
          className="max-h-36"
          options={props.options}
          open={openedSelection}
          handleSelect={handleAddOrDeleteOption}
          selectedOptions={props.selectedOptions}
        />
      </div>
    </>
  );
};
export default MuliSelectOptions;
