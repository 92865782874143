import "./login.css";
import { useEffect, useRef, useState } from "react";

import { useLoginMutation, useLazyGetMeQuery } from "../../store/apis/userApi";
import { LoginData } from "../../types/userTypes";
import LoginPageView from "./LoginPageView";
import { useAppDispatch, useAppSelector } from "hooks/storHook";
import { clearUser, setUserProperties } from "store";
import { useNavigate } from "react-router-dom";
import { AdminPaths, MediatorPaths } from "../../utils/utilsData";
import { getRole } from "../../utils/utilsFunction";

const LoginPage = () => {
  //hooks====
  const navigate = useNavigate();
  // State=======================
  const [loginData, setLoginData] = useState<LoginData>({
    email: "",
    password: "",
  });

  const user = useAppSelector((state) => state.user);

  useEffect(() => {
    if (user.id > 0) {
      const role = getRole(user);
      if (role === "Mediator") {
        navigate(MediatorPaths.mediatorDashboard);
      } else if (role === "Admin") {
        navigate(AdminPaths.adminDashboard);
      } else {
        dispatch(clearUser());
      }
    }
  }, []);

  const inputRefs: { [key: string]: React.RefObject<HTMLInputElement> } = {
    email: useRef<HTMLInputElement>(null),
    password: useRef<HTMLInputElement>(null),
  };

  //API=======================

  const [login, { isLoading, error }] = useLoginMutation();
  const [getMe] = useLazyGetMeQuery();

  //VARS==============
  const dispatch = useAppDispatch();

  // Handlers=======================

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (inputRefs[name]) inputRefs[name].current!.innerText = "";

    setLoginData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  //Functions=======================

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { email, password } = loginData;
    if (inputRefs.email.current && inputRefs.password.current) {
      if (!email) inputRefs.email.current.innerText = "Email is required";
      if (!password)
        inputRefs.password.current.innerText = "Password is required";
      if (!email || !password) return;
    }

    login(loginData).then((loginData) => {
      if (loginData.error) return;

      dispatch(setUserProperties({ ...loginData.data }));

      const { access } = loginData.data;
      getMe(access).then((userData) => {
        if (userData.error) return;

        const newUser = { ...userData.data };
        dispatch(setUserProperties(newUser));
        const role = getRole(newUser);
        if (role === "Admin") navigate(AdminPaths.adminDashboard);
        else if (role === "Mediator") navigate(MediatorPaths.mediatorDashboard);
      });
    });
  };

  //View=======================

  return (
    <LoginPageView
      handleChange={handleChange}
      onSubmit={onSubmit}
      loading={isLoading}
      inputRefs={inputRefs}
      loginError={error ? true : false}
    />
  );
};
export default LoginPage;
