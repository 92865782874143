import { AnimatePresence } from "framer-motion";
import { AnimateStages } from "./GuidedUtils";
import GreetingsStage from "./StageGreetingGuid";
import InitialStage from "./StageInitGuid";
import IterationStage from "./StageIterationGuid";
import StartStage from "./StageStartGuid";
import { StateWaitingInitialGuid } from "./StageWaiting";

interface GuidedNegotiationProps {
  jumpToSummary?: () => void;
  stage: number;
  iteration: number;
  initialIteration?: number;
  initialPosition?: { A: string; B: string };
  handleChangeStage: (idx: number) => void;
  greetingsMsg: string;
  onGreetingsChange: (msg: string) => void;
  handleStartNegotiation: (idx: number) => void;
  sendInitialPositionAction: ({ side }: { side: string }) => void;
  handleContinueAnyway: () => void;
  handleSummary: () => void;
  handleNextIteration: () => void;
  handleEndNegotiation: () => void;
  handleAddIteration: (num: number) => void;
  initialReceived: {
    A: boolean;
    B: boolean;
  };
  initData: {
    startWith: string;
    iterationNumber: number;
  };
  onInitChange: (
    data: Partial<{
      startWith: string;
      iterationNumber: number;
    }>,
    name: "startWith" | "iterationNumber"
  ) => void;
}

const GuidedNegotiation = ({
  stage,
  iteration,
  handleChangeStage,
  handleNextIteration,
  handleContinueAnyway,
  greetingsMsg,
  onGreetingsChange,
  onInitChange,
  handleEndNegotiation,
  initData,
  handleSummary,
  handleStartNegotiation,
  initialReceived,
  handleAddIteration,
  sendInitialPositionAction,
}: GuidedNegotiationProps) => {
  return (
    <AnimateStages
      stageKey="wrapper"
      className="w-full h-full bg-transparent  flex flex-col overflow-x-hidden"
    >
      <h1 className="text-lg font-mono text-center text-white mb-10">
        Guided Negotiation
      </h1>

      <AnimatePresence >
        {stage === 0 && (
          <AnimateStages stageKey="stage-0">
            <StartStage handleChangeStage={handleChangeStage} />
          </AnimateStages>
        )}

        {stage === 1 && (
          <AnimateStages stageKey="stage-1">
            <GreetingsStage
              message={greetingsMsg}
              setMessage={onGreetingsChange}
              handleChangeStage={handleChangeStage}
            />
          </AnimateStages>
        )}

        {stage === 2 && (
          <AnimateStages stageKey="stage-2">
            <InitialStage
              onInitChange={onInitChange}
              initData={initData}
              handleChangeStage={handleChangeStage}
              handleStartNegotiation={handleStartNegotiation}
            />
          </AnimateStages>
        )}
        {stage === 3 && (
          <AnimateStages stageKey="stage-3">
            <StateWaitingInitialGuid
              initialReceived={initialReceived}
              sendInitialAgain={sendInitialPositionAction}
              setContinueAnyWay={handleContinueAnyway}
            />
          </AnimateStages>
        )}

        {stage === 4 && (
          <AnimateStages stageKey="stage-3">
            <IterationStage
              handleSummary={handleSummary}
              handleContinue={handleNextIteration}
              iterationNumber={iteration}
              handleAddIteration={handleAddIteration}
              handleEndNegotiation={handleEndNegotiation}
            />
          </AnimateStages>
        )}
      </AnimatePresence>
    </AnimateStages>
  );
};

export default GuidedNegotiation;
