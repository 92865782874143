import { Logo } from "pages/login/LoginPageView";
import BubbleBackground from "../../components/background/BubbleBackground";
import { AnimatePresence, motion } from "framer-motion";

const LoadingPage = () => {
  return (
    <BubbleBackground>
      {Logo}

      <h1 className="text-xl mt-5">
        Loading{" "}
        <span className="loading align-bottom loading-dots loading-sm text-white"></span>
      </h1>
    </BubbleBackground>
  );
};
export default LoadingPage;

export const LoadingPageWithTransition = ({ isShown }:{ isShown: boolean }) => {
  return (
    <AnimatePresence>
      {isShown && (
        <motion.div
          exit={{ opacity: 0.3, transition: { delay: 0, duration: 1 } }}
        >
          <LoadingPage />
        </motion.div>
      )}
    </AnimatePresence>
  );
};
