import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "./storHook";
import { GreetingsInitialMsg } from "../components/chat/GuidedNegotiation/GuidedUtils";
import { useSocket } from "./useSocket";
import {
  addInitialPosition,
  addIteration,
  changeIteration,
  setGuidedNegotiation,
  setGuidedNegotiationStage,
} from "store";
import { ActionMsg, ActionPayloads, ActionTypes, Emits } from "types/CaseTypes";
import { MessageType } from "types/messageType";

const useGuidedChat = () => {
  const dispatch = useAppDispatch();
  const { stage, iteration, initialIteration, initialPosition } =
    useAppSelector((state) => state.guid);
  const user = useAppSelector((state) => state.user);
  const _case = useAppSelector((state) => state.case);
  const [greetingsMsg, setGreetingsMsg] = useState<string>(GreetingsInitialMsg);
  const [initialReceived, setInitialReceived] = useState<{
    A: boolean;
    B: boolean;
  }>({ A: false, B: false });
  const [continueAnyWay, setContinueAnyWay] = useState<boolean>(false);
  const { actions } = useAppSelector((state) => state.chat);

  const [initData, setInitData] = useState<{
    startWith: string;
    iterationNumber: number;
  }>({
    startWith: "A",
    iterationNumber: 2,
  });
  const { socket } = useSocket();

  const handleContinueAnyway = () => {
    setContinueAnyWay(true);
  };

  const getInitMessage = () => {
    return `
      Party A and Party B, thank you very much.
      You have ${initData.iterationNumber} interactions for this session.
      party ${initData.startWith} begins.`;
  };

  const handleChangeStage = (idx: number) => {
    dispatch(setGuidedNegotiationStage(idx));
    socket?.emit(Emits.guid, { stageIdx: idx, case_id: _case.case_id });
  };

  const handleStartNegotiation = (idx: number) => {
    socket?.emit(Emits.guid, {
      initialIteration: idx,
      iteration: idx,
      case_id: _case.case_id,
    });
    dispatch(setGuidedNegotiation({ isStarted: true, iteration: idx }));
    setInitialReceived({ A: false, B: false });
    dispatch(addInitialPosition({ A: "", B: "" }));

    const greetMsg: MessageType = {
      message: greetingsMsg,
      displayName: user.first_name + " " + user.last_name,
      side: "M",
      time: new Date(),
      from: user.id,
      to: _case.case_id,
      ext: {
        type: "guided",
        stage: "greetings",
      },
      fromSide: "M",
    };

    const initialMsg: MessageType = {
      message: "Party A and Party B please present your initial positions.",
      displayName: user.first_name + " " + user.last_name,
      side: "M",
      time: new Date(),
      from: user.id,
      to: _case.case_id,
      ext: {
        type: "guided",
        stage: "initial state",
      },
      fromSide: "M",
    };
    socket?.emit(Emits.roomMsg, greetMsg);

    setTimeout(() => {
      socket?.emit(Emits.roomMsg, initialMsg);
      sendInitialPositionAction({ side: "M" });
    }, 1000);
  };

  const sendInitialPositionAction = ({ side }: { side: string }) => {
    const initPosMsg: ActionMsg = {
      actionPayload: ActionPayloads.initialPosition,
      actionType: ActionTypes.add,
      displayName: user.first_name + " " + user.last_name,
      side: side || "M",
      time: new Date(),
      from: user.id,
      to: _case.case_id,
    };
    socket?.emit(Emits.action, initPosMsg);
  };

  useEffect(() => {
    if (!initialPosition?.A && !initialPosition?.B && !continueAnyWay) return;
    if (stage !== 3) return;
    const receiveA = initialPosition?.A !== "";
    const receiveB = initialPosition?.B !== "";
    setInitialReceived({ A: receiveA, B: receiveB });

    if ((receiveA && receiveB) || continueAnyWay) {
      setTimeout(() => {
        handleChangeStage(4);
      }, 1500);
      sendStartNegotiationMsg(initialIteration);
      socket?.emit(Emits.guid, { initialIteration, case_id: _case.case_id });
    }
  }, [initialPosition, continueAnyWay]);

  const sendStartNegotiationMsg = (idx: number) => {
    setTimeout(() => {
      const initMsg: MessageType = {
        message: getInitMessage(),
        displayName: user.first_name + " " + user.last_name,
        side: "M",
        time: new Date(),
        from: user.id,
        to: _case.case_id,
        ext: {
          type: "guided",
          stage: "initial",
        },
        fromSide: "M",
      };
      socket?.emit(Emits.roomMsg, initMsg);
      setTimeout(() => {
        sendIterationMsg({ initialNumber: idx, iteration: idx });
      }, 1000);
    }, 1200);
  };

  const sendIterationMsg = ({
    initialNumber,
    iteration,
  }: {
    initialNumber: number;
    iteration: number;
  }) => {
    if (iteration === 0) return;
    let iter = initialNumber - iteration + 1;

    let iterMsg: string = `Iteration ${iter} / ${initialNumber}`;

    const iterMsgObj: MessageType = {
      message: iterMsg,
      displayName: user.first_name + " " + user.last_name,
      side: "M",
      time: new Date(),
      from: user.id,
      to: _case.case_id,
      ext: {
        type: "guided",
        stage: "iteration",
      },
      fromSide: "M",
    };
    socket?.emit(Emits.roomMsg, iterMsgObj);
  };

  const handleNextIteration = () => {
    sendIterationMsg({
      initialNumber: initialIteration,
      iteration: iteration - 1,
    });
    dispatch(changeIteration(-1));
    socket?.emit(Emits.guid, {
      iteration: iteration - 1,
      case_id: _case.case_id,
    });
  };
  const handleEndNegotiation = () => {
    socket?.emit(Emits.guid, {
      case_id: _case.case_id,
      stageIdx: 0,
      initialIteration: 0,
      iteration: 0,
    });
    dispatch(setGuidedNegotiation({ isStarted: false, iteration: 0 }));
    setInitialReceived({ A: false, B: false });
    socket?.emit(Emits.endGuid, { case_id: _case.case_id });
  };

  const handleAddIteration = (num: number) => {
    const addIterationMsg: MessageType = {
      message: `Party A and Party B, thank you very much. You have added ${num} more interactions for this session. party ${initData.startWith} begins.`,
      displayName: user.first_name + " " + user.last_name,
      side: "M",
      time: new Date(),
      from: user.id,
      to: _case.case_id,
      ext: {
        type: "guided",
        stage: "Iteration Increase",
      },
      fromSide: "M",
    };
    socket?.emit(Emits.roomMsg, addIterationMsg);
    socket?.emit(Emits.guid, {
      initialIteration: initialIteration + num,
      case_id: _case.case_id,
    });

    dispatch(addIteration(num));

    dispatch(changeIteration(num));
  };

  const onGreetingsChange = (msg: string) => {
    setGreetingsMsg(msg);
  };

  const onInitChange = (
    data: Partial<{
      startWith: string;
      iterationNumber: number;
    }>,
    name: "startWith" | "iterationNumber"
  ) => {
    setInitData((prev) => ({
      ...prev,
      [name]: data,
    }));
  };

  return {
    stage,
    iteration,
    initialIteration,
    initialPosition,
    greetingsMsg,
    initialReceived,
    continueAnyWay,
    actions,
    initData,
    handleContinueAnyway,
    getInitMessage,
    handleChangeStage,
    handleStartNegotiation,
    sendInitialPositionAction,
    sendStartNegotiationMsg,
    sendIterationMsg,
    handleNextIteration,
    handleEndNegotiation,
    handleAddIteration,
    onGreetingsChange,
    onInitChange,
  };
};
export default useGuidedChat;
