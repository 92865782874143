import FormInput from "../../components/input/formInput";
import DynamicHero from "../../components/background/DynamicHero";
import PopButton from "../../components/buttons/PopButton";
import { LightHeadline } from "../../components/background/headline";
import { Button } from "@/components/ui/button";
import { Link } from "react-router-dom";

interface LoginPageViewProps {
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  loading?: boolean;
  inputRefs?: { [key: string]: React.RefObject<HTMLInputElement> };
  loginError?: boolean;
}

export const Logo = (
  <h1 className="logo text-4xl text-[#479a79] text-center mt-2">NEGOFLICT</h1>
);

const LoginPageView = ({
  handleChange,
  onSubmit,
  loading,
  inputRefs,
  loginError,
}: LoginPageViewProps) => {
  return (
    <div className="">
      <DynamicHero />
      <div className="w-full h-full relative">
        <div className=" z-10  w-4/5 h-2/3  absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] ">
          <div className="z-10">{Logo}</div>
          <center className="p-2 flex flex-col justify-between items-stretch h-full  ">
            <header className="min-h-20">
              <h1 className="text-4xl mb-4 font-extralight font-serif text-[#f6f6f6]  ">
                Sign in
              </h1>
              {loginError && (
                <p className="text-rose-300">
                  No active account found with the given credentials
                </p>
              )}
            </header>
            <form onSubmit={onSubmit}>
              <main className="flex flex-col gap-2 justify-center items-center">
                <FormInput
                  autoComplete="email"
                  statusRef={inputRefs?.email}
                  name="email"
                  handelChange={handleChange}
                  placeHolder="Email"
                  rightLabel="Require"
                  type="email"
                />
                <FormInput
                  autoComplete="current-password"
                  statusRef={inputRefs?.password}
                  name="password"
                  handelChange={handleChange}
                  placeHolder="Password"
                  rightLabel="Require"
                  secure
                />
                <footer className="w-full flex justify-center mt-10">
                  <PopButton loading={loading} text="Submit" />
                </footer>
                <div className="flex flex-grow text-emerald-300">
                  <p>
                    <br />
                    Please make sure to read our declaimer{" "}
                    <span className="text-rose-300 btn btn-link m-0 p-0">
                      here.
                    </span>{" "}
                    <br />
                  </p>
                </div>
              </main>
            </form>
            <div className="my-4">
              <Link to="/client-login">
                <Button>Login As Client</Button>
              </Link>
            </div>

            <p className="text-xs text-white">
              Please note that only registered mediator can log in. <br />
              If you are not registered, please contact the admin.
            </p>
          </center>
        </div>
      </div>
      <LightHeadline />
    </div>
  );
};

export default LoginPageView;
