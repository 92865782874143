import PopButton from "../../buttons/PopButton";
import { motion } from "framer-motion";

interface ContinueButtonsProps {
  handleChangeStage: (idx: number) => void;
  initIndex: number;
}
export const ContinueButtons = (props: ContinueButtonsProps) => {
  return (
    <div className="h-fit shrink-0 flex gap-2 justify-start">
      <PopButton
        color="bg-rose-300"
        text="Back"
        arrowLeft
        handleClick={() => props.handleChangeStage(props.initIndex - 1)}
      />
      <PopButton
        text="Next"
        handleClick={() => props.handleChangeStage(props.initIndex + 1)}
      />
    </div>
  );
};

export const AnimateStages = ({
  children,
  stageKey,
  className,
}: {
  children?: React.ReactNode;
  stageKey: string;
  className?: string;
}) => {
  return (
    <motion.div
      initial={{ x: "-10%", opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      exit={{ x: "-10%", opacity: 0 }}
      transition={{ type: "spring", stiffness: 300, damping: 30 }}
      key={stageKey}
      className={className}
    >
      {children}
    </motion.div>
  );
};

export const GreetingsInitialMsg =
  "Hello party A and party B Greetings and all the best. I am glad that you took the time to discuss a very important issue concerning………..";

export type NoteType = {
  note: string;
  groupTag: "A" | "B" | "Both";
};
