interface FormInputProps {
  placeHolder?: string;
  leftLabel?: string;
  rightLabel?: string;
  secure?: boolean;
  type?: string;
  handelChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  name: string;
  statusRef?: React.RefObject<HTMLSpanElement>;
  autoComplete?: React.HTMLInputAutoCompleteAttribute;
  autoFocus?: boolean;
  value?: string;
  className?: string;
  register?: any;
  bottomLabel?:string;
}

const FormInput = (props: FormInputProps) => {
  return (
    <label className="form-control w-full max-w-xs">
      <div className="label h-10">
        <span ref={props.statusRef} className="label-text">
          {props.leftLabel}
        </span>
        <span className="label-text-alt">{props.rightLabel}</span>

      </div>
      <input
        {...props.register?.(props.name)}
        value={props.value}
        autoFocus={props.autoFocus}
        autoComplete={props.autoComplete}
        name={props.name}
        onChange={props.handelChange}
        type={props.secure ? "password" : props.type ? props.type : "text"}
        placeholder={props.placeHolder}
        className={`input input-bordered w-full max-w-xs bg-[#1D232A] text-white ${props.className}`}
      />
       <div className="label w-full h-[1rem] ">
    <span className="label-text-alt text-sm text-rose-400 ">{props.bottomLabel || ''}</span>

  </div>
    </label>
  );
};
export default FormInput;
