import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

interface sendCaseInviteViaEmailType {
  email: string;
  sender_email: string;
  link: string;
}

export const sessionApi = createApi({
  reducerPath: "sessionApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "/api",
    prepareHeaders: (headers, { getState }: { getState: () => any }) => {
      const access = getState().user.access;
      if (access) headers.set("Authorization", `JWT ${access}`);
      return headers;
    },
  }),
  endpoints(builder) {
    return {
      sendCaseInviteViaEmail: builder.mutation({
        query: (data: sendCaseInviteViaEmailType) => {
          return {
            url: "/session/send-invite-email/",
            method: "POST",
            body: data,
          };
        },
      }),
    };
  },
});
export const { useSendCaseInviteViaEmailMutation } = sessionApi;
