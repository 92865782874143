import { motion } from "framer-motion";
import { DROP_ITEM_VARIANT, DROP_VARIANT } from "./variants";

//TODO: test this component and integrate it in AddCase.tsx
interface DropDownBoxProps {
  options: string[];
  open: boolean;
  handleSelect: (val: string) => void;
  selectedOptions?: Set<string>;
  className?: string;
}

export const DropDownBox = ({
  options,
  open,
  handleSelect,
  selectedOptions,
  className,
}: DropDownBoxProps) => {
  return (
    <>
      <motion.div
        animate={open ? "open" : "closed"}
        initial={DROP_VARIANT.closed}
        variants={DROP_VARIANT}
        className={` ${className} bg-[#1D232A] w-full max-h-24 overflow-y-scroll snap-y snap-mandatory snap-always  absolute top-[3rem] flex flex-col gap-2 `}
      >
        {options.map((option, index) => {
          let isSelected = false;
          if (selectedOptions && selectedOptions.has(option)) {
            isSelected = true;
          }
          return (
            <motion.span
              key={index}
              onClick={() => handleSelect(option)}
              variants={DROP_ITEM_VARIANT}
              className={`rounded-none py-1 snap-start ${
                isSelected && "bg-slate-100 text-black"
              } cursor-pointer hover:backdrop-brightness-200 w-full `}
            >
              {option}
            </motion.span>
          );
        })}
      </motion.div>
    </>
  );
};
