import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { CaseSaveType } from "types/CaseTypes";
import { Participant } from "types/userTypes";

const initialState: CaseSaveType = {
  link: "",
  case_id: "",
  case_title: "",
  shuttle: false,
  participants: [],
};

const caseSlice = createSlice({
  name: "case",
  initialState,
  reducers: {
    setCase: (state, action: PayloadAction<Partial<CaseSaveType>>) => {
      const newState = { ...state, ...action.payload };
      return newState;
    },
    addParticipant: (state, action: PayloadAction<Participant>) => {
      state.participants.push(action.payload);
    },
    clearCase: (state) => {
      return initialState;
    },
  },
});

export const { setCase, clearCase, addParticipant } = caseSlice.actions;
export const caseReducer = caseSlice.reducer;
