import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import Headline from "../../components/background/headline";
import { useLocation } from "react-router-dom";
import { useGetCaseByIdQuery } from "store/apis/caseApi";
import { CaseReturnType } from "types/CaseTypes";
import { AnimatePresence, motion } from "framer-motion";
import BubbleBackground from "../../components/background/BubbleBackground";
import { Logo } from "pages/login/LoginPageView";

const InfoPage = () => {
  const location = useLocation();
  const lastPathSegment = location.pathname.split("/").pop();
  const { data, error, isLoading } = useGetCaseByIdQuery(
    lastPathSegment as string
  );

  return (
    <section className="w-full flex flex-col h-[100dvh] overflow-auto bg-[#414a4f] px-4 py-12 text-slate-50">
      <Headline text="Case Info." />

      {isLoading && (
        <div className="absolute left-0 right-0 top-0 bottom-0">
          <BubbleBackground></BubbleBackground>
        </div>
      )}
      <AnimatePresence mode='sync'>
        {isLoading && (
          <motion.div
          initial={{ opacity: 1 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0, transition: { delay: 0.6, duration:0.5 } }}

           className=" fixed  left-[50%] top-[50%] z-50  translate-x-[-50%] translate-y-[-50%]">
            {Logo}
            <h1 className="text-xl mt-5">
              Loading{" "}
              <span className="loading align-bottom loading-dots loading-sm text-white"></span>
            </h1>
          </motion.div>
        )}
      </AnimatePresence>

      <div className="grid gap-2 w-full h-fit min-h-full grid-cols-1 md:grid-cols-2 grid-auto-flow-row ">
        <div className="w-full ">
          <motion.div
            className="z-[2]"
            initial={{ opacity: 0, y: 50 }}
            animate={!isLoading &&{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <Table className=" opacity-75 z- text-slate-400  rounded-sm  bg-slate-900  ">
              <TableCaption>Basic case Information</TableCaption>
              <TableHeader className="">
                <TableRow>
                  <TableHead className="text-white underline">Key</TableHead>
                  <TableHead className="text-white underline">Value</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {data &&
                  Object.entries(data as CaseReturnType).map(([key, value]) => {
                    return (
                      <TableRow className="hover:text-slate-100 " key={key}>
                        <TableCell className="hover:animate-pulse">
                          {key}:
                        </TableCell>

                        <TableCell className="bg-slate-950 text-white">
                          {value}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </motion.div>
        </div>
        <div className=" h-full w-full mb-2 ">
          <motion.div
            initial={{ opacity: 0, x: 100 }}
            animate={!isLoading && { opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.1 }}
            className="stats stats-vertical shadow z-[0]"
          >
            <div className="stat stats-horizontal">
              <div className="stat">
                <div className="stat-title">Messages</div>
                <div className="stat-value">31K</div>
                <div className="stat-desc">
                  Total number of messages sended{" "}
                </div>
              </div>
              <div className="stat">
                <div className="stat-title">Messages private </div>
                <div className="stat-value">4,200</div>
                <div className="stat-desc">↗︎ 400 (22%)</div>
              </div>
            </div>
            <div className="stat stats-horizontal">
              <div className="stat">
                <div className="stat-title">Messages</div>
                <div className="stat-value">31K</div>
                <div className="stat-desc">
                  Total number of messages sended{" "}
                </div>
              </div>
              <div className="stat">
                <div className="stat-title">Messages private </div>
                <div className="stat-value">4,200</div>
                <div className="stat-desc">↗︎ 400 (22%)</div>
              </div>
            </div>
            <div className="stat">
              <div className="stat-title">Messages</div>
              <div className="stat-value">31K</div>
              <div className="stat-desc">Total number of messages sended </div>
            </div>
            <div className="stat">
              <div className="stat-title">Messages</div>
              <div className="stat-value">31K</div>
              <div className="stat-desc">Total number of messages sended </div>
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
};
export default InfoPage;
