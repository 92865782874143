import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

const initialState: string[] = [];

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    addNotification: (state, action: PayloadAction<string>) => {
      state.push(action.payload);
    },
    removeNotification: (state) => {
      state.shift();
    },
    clearNotifications: (state) => {
      return [];
    },
  },
});

export const { addNotification, removeNotification, clearNotifications } = notificationSlice.actions;
export const notificationReducer = notificationSlice.reducer;
