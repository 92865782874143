export const getRole = ({
  is_staff,
  is_superuser,
}: {
  is_staff: boolean;
  is_superuser: boolean;
}) => {
  if (is_superuser) return "Admin";
  if (is_staff) return "Mediator";
  return "User";
};
