import { TbLetterA, TbLetterB, TbLetterM } from "react-icons/tb";
import { IoBusinessOutline } from "react-icons/io5";
import { PiChatCenteredSlash } from "react-icons/pi";
import { MdOutlineNotifications } from "react-icons/md";
import { useEffect, useMemo } from "react";
import { MdFamilyRestroom } from "react-icons/md";
import { FaUserFriends } from "react-icons/fa";
import { TbSchool } from "react-icons/tb";
import { VscOrganization } from "react-icons/vsc";
import { MdBusinessCenter } from "react-icons/md";
import { LiaUniversitySolid } from "react-icons/lia";
import { LuBriefcase } from "react-icons/lu";
import { PiChatCenteredTextLight } from "react-icons/pi";
import { Separator } from "@/components/ui/separator";
import { CiSearch } from "react-icons/ci";
import { MdOutlineNotificationsActive } from "react-icons/md";
import { useAppSelector } from "hooks/storHook";

interface InfoBarProps {
  side: "A" | "B" | "M";
  case_category: string;
  isShuttle: boolean;
}

const sidesObject = {
  A: <TbLetterA className="  w-full text-2xl  " />,
  B: <TbLetterB className="  w-full text-2xl  " />,
  M: <TbLetterM className="  w-full text-2xl  " />,
};

const categoriesObject = {
  Family: <MdFamilyRestroom className="  w-full text-2xl  " />,
  Friends: <FaUserFriends className="  w-full text-2xl  " />,
  Neighbors: <FaUserFriends className="  w-full text-2xl  " />,
  Education: <TbSchool className="  w-full text-2xl  " />,
  Organization: <VscOrganization className="  w-full text-2xl  " />,
  Business: <MdBusinessCenter className="  w-full text-2xl  " />,
  Politics: <LiaUniversitySolid className="  w-full text-2xl  " />,
  Other: <LuBriefcase className="  w-full text-2xl  " />,
};

const InfoBar = (props: InfoBarProps) => {
  const notifications = useAppSelector((state) => state.notification);
  const sideIcon = useMemo(() => {
    return sidesObject[props.side || "M"];
  }, [props.side]);

  const categoryIcon = useMemo(() => {
    return categoriesObject[
      (props.case_category as keyof typeof categoriesObject) || "Other"
    ];
  }, [props.case_category]);

  return (
    <div className="grid h-full  grid-cols-5  max-w-64 min-w-60 bg-[#414a4f]  text-black  rounded-tr-xl ">
      <div id="info-bar-side" className="w-full h-16 flex items-center  ">
        {sideIcon}
        <Separator orientation="vertical" className="h-1/2 bg-black" />
      </div>

      <div className="w-full h-16 flex items-center  ">
        {categoryIcon}
        <Separator orientation="vertical" className="h-1/2 bg-black" />
      </div>

      <div className="w-full h-16 flex items-center  ">
        {props.isShuttle ? (
          <PiChatCenteredSlash className="  w-full text-2xl text-rose-500 " />
        ) : (
          <PiChatCenteredTextLight className="  w-full text-2xl   " />
        )}
        <Separator orientation="vertical" className="h-1/2 bg-black " />
      </div>

      <div className="w-full h-16 flex items-center  ">
        {notifications.length > 0 ? (
          <MdOutlineNotificationsActive className=" w-full text-2xl text-amber-500  " />
        ) : (
          <MdOutlineNotifications className=" w-full text-2xl  " />
        )}

        <Separator orientation="vertical" className="h-1/2 bg-black" />
      </div>
      <div className="w-full h-16 flex items-center active:scale-95  cursor-pointer ">
        <CiSearch className="  w-full text-2xl z-[2] " />
      </div>
    </div>
  );
};
export default InfoBar;
