import { useScroll } from "framer-motion";
import { useEffect, useRef } from "react";

interface SmallCarouselProps {
  setSelection: (num: any) => void;
  items: { color?: string; item: any }[];
  initState?: any;
}

const SmallCarousel = (props: SmallCarouselProps) => {
  const carouselRef = useRef<HTMLDivElement | null>(null);
  const initRef = useRef<HTMLDivElement | null>(null);
  const wasRendered = useRef(false);
  const { scrollYProgress } = useScroll({ container: carouselRef });

  useEffect(() => {
    scrollYProgress.on("change", (latest) => {
      const index = Math.round(latest * (props.items.length - 1));
      props.setSelection(props.items[index].item);
    });
  }, []);

  useEffect(() => {
    if (wasRendered.current) return;
    wasRendered.current = true;
    if (!initRef.current) return;
    initRef.current.scrollIntoView({ behavior:'auto'});
  }, [initRef.current]);

  return (
    <div>
      <div
        ref={carouselRef}
        className="snap-y snap-mandatory snap-always h-16 w-16 overflow-y-scroll"
      >
        {props.items.map(({ item, color }, i) => (
          <div
            key={i}
            ref={props.initState === item ? initRef : null}
            className={`w-16 h-16 ${
              color || "bg-emerald-500"
            } snap-center flex ring-4 ring-offset-2 justify-center items-center`}
          >
            <p className="text-white text-5xl font-thin">{item}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SmallCarousel;
