import { useAppSelector } from "hooks/storHook";
import { useEffect, useRef } from "react";
import { GroupType } from "types/messageType";

interface GroupHandlerProps {
  handleChangeGroup: (group: GroupType) => void;
  selectedGroup: GroupType;
  role: string;
}
const GroupHandler = (props: GroupHandlerProps) => {
  const groups = useRef<GroupType[]>([]);
  const user = useAppSelector((state) => state.user);

  const notificationMap = useAppSelector((state) => state.chat.notification);

  useEffect(() => {
    if (props.role === "Mediator") {
      groups.current = ["M", "A", "B"];
    } else {
      groups.current = ["M", user.side as GroupType];
    }
  }, [user.side]);

  return (
    <>
      {groups.current.length &&
        groups.current.map((group) => (
          <button
            key={group}
            onClick={() => props.handleChangeGroup(group as "A" | "M" | "B")}
            className={` btn btn-ghost indicator ${
              props.selectedGroup === group
                ? "bg-gradient-to-t from-emerald-500 to-emerald-700"
                : "bg-gradient-to-t from-gray-500 to-gray-700"
            }
                rounded-sm w-10 sm:w-12  `}
          >
            {notificationMap[group] > 0 && (
              <>
                <div className="indicator-item badge bg-indigo-500 rounded-sm">
                  <p className=" text-slate-300">
                    {notificationMap[group] > 99
                      ? "99+"
                      : notificationMap[group]}
                  </p>
                </div>
              </>
            )}

            <span className=" font-serif text-white text-xl font-extralight ">
              {group}
            </span>
          </button>
        ))}
    </>
  );
};

export default GroupHandler;
