import {
  FaChevronRight,
  FaChevronDown,
  FaChevronUp,
  FaChevronLeft,
} from "react-icons/fa";
import { PiChatsLight } from "react-icons/pi";
import { RiCloseLargeFill } from "react-icons/ri";
import { Button } from "@/components/ui/button";

const ArrowIcons = {
  right: <FaChevronRight className="h-4 w-4" />,
  down: <FaChevronDown className="h-4 w-4" />,
  left: <FaChevronLeft className="h-4 w-4" />,
  up: <FaChevronUp className="h-4 w-4" />,
  close: <RiCloseLargeFill className="h-4 w-4" />,
};

interface ArrowButtonProps {
  direction: "right" | "down" | "left" | "up"| 'close';
  onClick?: () => void;
  className?: string;
}

export function ArrowButton(props: ArrowButtonProps) {
  return (
    <Button
      onClick={props.onClick}
      variant="ghost"
      size="icon"
      className={` ${props.className} border-collapse hover:bg-transparent hover:border-[0.3px] hover:backdrop-brightness-125  border-white  `}
    >
      {ArrowIcons[props.direction]}
    </Button>
  );
}

interface ArrowButtonWithTextProps extends ArrowButtonProps {
  text: string;
  icon?: React.ReactNode;
  children?: React.ReactNode;
}

export const ArrowButtonWithText = (props: ArrowButtonWithTextProps) => {
  return (
    <div className="flex justify-center items-center gap-1 ">
      <div className="text-slate-300 font-extralight text-xl flex gap-1 items-center  ">
        {props.icon || <PiChatsLight className="h-4 w-4" />}
        <h1>{props.text}</h1>
      </div>

      <ArrowButton {...props} />
    </div>
  );
};
