import RegExp from "../../components/input/regExp";
import Headline from "../../components/background/headline";
import { Logo } from "pages/login/LoginPageView";
import BubbleBackground from "../../components/background/BubbleBackground";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "hooks/storHook";
import { setCase, setUserProperties, clearCase, clearUser } from "store";
import { useEffect } from "react";

type formDataTypeStart = {
  email: string;
  firstName: string;
  lastName: string;
  id: number;
  case_id: string;
  side: string;
};

const ClientLogin = () => {
  const navigator = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(clearUser());
  }, []);

  const handleStart = (data: formDataTypeStart) => {
    const { email, firstName, lastName, id, case_id, side } = data;
    dispatch(
      setUserProperties({
        email,
        first_name: firstName,
        last_name: lastName,
        id,
        side,
      })
    );
    navigator("/User/dashboard", { state: { case_id } });
  };

  return (
    <BubbleBackground>
      <div className="flex flex-col gap-5  ">
        <div className=""> {Logo}</div>

        <div className="w-full  h-52 ">
          <RegExp handleStart={handleStart} />
        </div>
      </div>
    </BubbleBackground>
  );
};
export default ClientLogin;
