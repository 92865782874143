import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { IoIosArrowDropdown } from "react-icons/io";
import { DROP_ITEM_VARIANT, DROP_VARIANT } from "./variants";

interface CostumeDropDownProps {
  initialValue: string;
  options: string[];
  name: string;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  statusRef?: React.RefObject<HTMLSpanElement>;
}

const CostumeDropDown = ({
  initialValue,
  options,
  handleChange,
  name,
  statusRef,
}: CostumeDropDownProps) => {
  const noneValue: string = initialValue;
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState<string>(noneValue);

  const handleOpen = () => setOpen((pv) => !pv);

  const handleSelect = (val: string) => {
    setValue(val);
    handleOpen();
  };
  useEffect(() => {
    if (value === noneValue) return;
    const syntheticEvent = {
      target: {
        value,
        name,
      },
    } as React.ChangeEvent<HTMLInputElement>;
    handleChange(syntheticEvent);
  }, [value]);

  return (
    <div className="max-w-[20rem] w-full ">
      <div className="label">
        <span ref={statusRef} className="label-text-alt"></span>
        <span className="label-text-alt">Require</span>
      </div>

      <div className="w-full justify-center flex  relative ">
        <input
          disabled
          value={value}
          name={name}
          type="text"
          className={`input input-bordered flex-grow rounded-r-none disabled:border-none disabled:bg-[#1D232A] disabled:text-white ${
            open && "rounded-b-none"
          }    bg-[#1D232A] text-white " disabled placeholder="Category`}
        />
        <button
          onClick={handleOpen}
          className={`btn btn-square btn-ghost rounded-l-none backdrop-brightness-200 ${
            open && "rounded-b-none"
          }  `}
        >
          <IoIosArrowDropdown className="w-[75%] h-[75%]" />
        </button>

        <motion.div
          animate={open ? "open" : "closed"}
          initial={DROP_VARIANT.closed}
          variants={DROP_VARIANT}
          className=" bg-[#1D232A] w-full  absolute top-[3rem] flex flex-col gap-2 "
        >
          {options.map((option, index) => (
            <motion.span
              key={index}
              onClick={() => handleSelect(option)}
              variants={DROP_ITEM_VARIANT}
              className="rounded-none py-1 cursor-pointer hover:backdrop-brightness-200 w-full "
            >
              {option}
            </motion.span>
          ))}
        </motion.div>
      </div>
    </div>
  );
};


export default CostumeDropDown;
