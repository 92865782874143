import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { clear } from "console";

interface IUser {
  id: number;
  first_name: string;
  last_name: string;
  access?: string;
  email: string;
  username?: string;
  is_staff: boolean;
  is_superuser: boolean;
  first_logged?: boolean;
  side?: string;
}

const initialState: IUser = {
  id: -1,
  first_name: "",
  last_name: "",
  access: "",
  email: "",
  username: "",
  is_staff: false,
  is_superuser: false,
  first_logged: false,
};

const UserSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserProperties: (state, action: PayloadAction<Partial<IUser>>) => {
      const newState = { ...state, ...action.payload };
      return newState;
    },
    clearUser: () => initialState,
  },
});

export const { setUserProperties, clearUser } = UserSlice.actions;
export const userReducer = UserSlice.reducer;
