import { useAppDispatch } from "hooks/storHook";
import { clearUser } from "store";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const Logout = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(clearUser());

    navigate("/login");
  }, []);

  return <></>;
};
export default Logout;
