import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { GroupType } from "types/messageType";

interface Notifications {
  [key: string]: number;
}

interface ChatState {
  notification: Notifications;
  actions: string[];
  shuttle: boolean;
}

const initialState: ChatState = {
  notification: { A: 0, M: 0, B: 0 },
  actions: [],
  shuttle: false,
};

const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    setNotification: (state, action: PayloadAction<GroupType>) => {
      const groupType: GroupType = action.payload;
      const counter: number = state.notification[groupType] || 0;

      state.notification[groupType] = counter + 1;
    },
    clearNotification: (state, action: PayloadAction<GroupType>) => {
      const groupType: GroupType = action.payload;
      state.notification[groupType] = 0;
    },
    addAction: (state, action: PayloadAction<string>) => {
      if (state.actions.includes(action.payload)) {
        return;
      }
      state.actions.push(action.payload);
    },
    setActions: (state, action: PayloadAction<string[]>) => {
      state.actions = [...action.payload];
    },
    clearAction: (state, action: PayloadAction<string>) => {
      state.actions = state.actions.filter((act) => act !== action.payload);
    },
    setShuttle: (state, action: PayloadAction<boolean>) => {
      state.shuttle = action.payload;
    },
  },
});

export const {
  setNotification,
  clearNotification,
  addAction,
  clearAction,
  setActions,
  setShuttle,
} = chatSlice.actions;
export const chatReducer = chatSlice.reducer;
