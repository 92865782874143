import {
    TbCircleLetterAFilled,
    TbCircleLetterBFilled,
    TbCircleLetterMFilled,
  } from "react-icons/tb";

export const participantIcons = {
    M: <TbCircleLetterMFilled color="white" className="w-full h-full" />,
    A: <TbCircleLetterAFilled color="white" className="w-full h-full" />,
    B: <TbCircleLetterBFilled color="white" className="w-full h-full" />,
  };

 export const participantColors = {
    M: "bg-violet-600",
    A: "bg-teal-600",
    B: "bg-rose-600",
  };
