


export enum MediatorPaths {
    createCase = '/Mediator/new-case',
    mediatorDashboard = '/Mediator/dashboard'

}
export enum AdminPaths {
    addMediator = '/Admin/add-mediator',
    adminDashboard = '/Admin/dashboard'
}