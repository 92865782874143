import { useSocket } from "./useSocket";

type listenersType = {
  [key: string]: (data?: any) => any;
};

const useChatSocket = () => {
  const { socket } = useSocket();
  const listeners: listenersType = {};

  /**
   * Add a listener to the socket
   * @param listener [key: string]: (data?: any) => any - An object with the event name as the key and the callback as the value
   *
   */
  const on = (listener: listenersType) => {
    Object.entries(listener).forEach(([event, cb]) => {
      listeners[event] = cb;
      socket?.on(event, cb);
    });
  };
  /**
   * Add multiple listeners to the socket
   * @param data [key: string]: (data?: any) => any - An array of objects with the event name as the key and the callback as the value
   */
  const ons = (data: listenersType[]) => {
    data.forEach((listener) => {
      on(listener);
    });
  };
  /**
   * Remove a listener from the socket
   * @param event string - The event name to remove the listener from
   */
  const offAllListeners = () => {
    Object.keys(listeners).forEach((event) => {
      socket?.off(event, listeners[event]);
    });
  };

  const off = (listener: listenersType) => {
    Object.entries(listener).forEach(([event, cb]) => {
      socket?.off(event, cb);
    });
  };

  return { on, ons, offAllListeners, socket, off };
};

export default useChatSocket;
