import { LightHeadline } from "../../components/background/headline";
import { motion } from "framer-motion";
import { useState, useEffect } from "react";

interface BubbleProps {
  children?: React.ReactNode;
}

const BubbleBackground = ({ children }: BubbleProps) => {
  const NUM_CIRCLES = 25;

  return (
    <div className="flex relative h-[100dvh] w-full overflow-hidden bg-[#414a4f]">
      <LightHeadline />
      <div className="absolute font-serif left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] z-50">
        {children}
      </div>
      {Array.from({ length: NUM_CIRCLES }).map((_, i) => {
        return <Bubble key={i} />;
      })}
    </div>
  );
};

const Bubble = () => {
  const [targetPosition, setTargetPosition] = useState({ x: 0, y: 0 });

  function sigmoidLike() {
    return Math.random() * 2 - 1;
  }

  useEffect(() => {
    const x = Math.floor((sigmoidLike() * window.innerWidth) / 2);
    const y = Math.floor((sigmoidLike() * window.innerHeight) / 2);
    setTargetPosition({ x, y });
  }, []);

  const variant = {
    open: {
      opacity: 1,

      x: 0,
      y: 0,
    },
    close: {
      x: [0, targetPosition.x, 0],
      y: [0, targetPosition.y, 0],
    },
  };

  return (
    <motion.div
      initial="open"
      animate="close"
      variants={variant}
      transition={{
        duration: Math.floor(Math.random() * 5 + 7),
        repeat: Infinity,
        repeatType: "loop",
        ease: "easeInOut",
      }}
      style={{
        left: Math.floor(Math.random() * window.innerWidth),
        top: Math.floor(Math.random() * window.innerHeight),
      }}
      className="absolute w-40 h-40 rounded-full shadow-2xl"
    />
  );
};

export default BubbleBackground;
