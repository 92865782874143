import { motion } from "framer-motion";
import "./hero.css";

const DynamicHero = () => {
  return (
    <div className="w-full h-[100dvh] bg-[#414a4f] absolute overflow-hidden min-h-[450px]">
      <motion.div
        initial={{
          top: "50%",
          right: "50%",
        }}
        animate={{
          x: [0, 100, 0], // Keyframes for horizontal movement
          y: [0, 100, 0], // Keyframes for vertical movement
        }}
        transition={{
          duration: 10, // Duration for one cycle of the animation
          repeat: Infinity, // Repeat the animation infinitely
          repeatType: "loop", // Type of repeat
        }}
        className="bg-circle-left"
      ></motion.div>
      <motion.div
        initial={{
          bottom: "50%",
          left: "50%",
        }}
        animate={{
          x: [0, -100, 0], // Keyframes for horizontal movement
          y: [0, -100, 0], // Keyframes for vertical movement
        }}
        transition={{
          duration: 15, // Duration for one cycle of the animation
          repeat: Infinity, // Repeat the animation infinitely
          repeatType: "loop", // Type of repeat
        }}
        className="bg-circle-right"
      ></motion.div>
    </div>
  );
};
export default DynamicHero;
