import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectGroup,
  SelectLabel,
  SelectItem,
} from "@/components/ui/select";

interface SelectInputProps {
  options?: string[];
  label?: string;
  selectValue?: string;
  required?: boolean;
  name: string;
  autoComplete?: React.HTMLInputAutoCompleteAttribute;
  onChange?: (name: string, value: string) => void;
}

const SelectInput = ({
  required,
  name,
  options,
  label,
  selectValue,
  onChange,
  autoComplete,
}: SelectInputProps) => {
  const onValueChange = (value: string) => onChange?.(name, value);
  return (
    <Select autoComplete={autoComplete} onValueChange={onValueChange} required={required}>
      <SelectTrigger className=" max-w-xs input input-bordered w-full h-12  bg-[#1D232A] text-white">
        <SelectValue placeholder={selectValue || options?.[0] || "select"} />
      </SelectTrigger>
      <SelectContent className="bg-[#1D232A] text-white">
        <SelectGroup>
          <SelectLabel className=" underline">{label}</SelectLabel>
          {options?.map((option) => (
            <SelectItem key={option} value={option}>
              {option}
            </SelectItem>
          ))}
        </SelectGroup>
      </SelectContent>
    </Select>
  );
};

export default SelectInput;
