import { Input } from "@/components/ui/input";
import SelectInput from "./SelectInput";
import { useRef } from "react";

interface SelectWithInputProps {
  options?: string[];
  name: string;
  selectName?: string;
  type?: string;
  register?: any;
  onChange?: (name: string, value: string) => void;
  autoComplete?: React.HTMLInputAutoCompleteAttribute;
  selectAutoComplete?: React.HTMLInputAutoCompleteAttribute;
}
const SelectWithInput = ({
  options,
  name,
  type,
  selectName,
  autoComplete,
  register,
  onChange,
  selectAutoComplete,
}: SelectWithInputProps) => {
  return (
    <>
      <div className="w-full h-full flex justify-center items-center">
        <div className="max-w-xs w-full h-12 grid grid-cols-6 gap-1">
          <div className="col-span-2">
            {" "}
            <SelectInput
              autoComplete={selectAutoComplete}
              onChange={onChange}
              name={selectName || name || ""}
              options={options}
            />
          </div>
          <div className="col-span-4">
            <Input
              {...register?.(name)}
              autoComplete={autoComplete}
              name={name}
              type={type || "tel"}
              className="input rounded-l-none input-bordered h-12 bg-[#1D232A] text-white"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default SelectWithInput;
