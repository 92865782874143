import { AnimatePresence, motion } from "framer-motion";
import { cloneElement, useState } from "react";

interface CircleButtonProps {
  icon: React.ReactNode;
  iconColor?: string;
  ringColor?: string;
  active?: number;
  onClick: (idx:number) => void;
  idx: number;
}

const CircleButton = (props: CircleButtonProps) => {


  return (
    <button
      onClick={()=>props.onClick(props.idx)}
      style={{ boxShadow: "0 0 3px 1px #4f46e5 inset" }}
      className="w-8 h-8 sm:w-10 sm:h-10 rounded-sm relative ring-1 ring-indigo-600 overflow-hidden "
    >
      <AnimatePresence>
        {props.active === props.idx  && (
          <motion.span
            initial={{ y: "100%" }}
            animate={{ y: "0%" }}
            exit={{ y: "100%" }}
            transition={{
              duration: 0.5,
              ease: "backIn",
            }}
            className="absolute inset-0 z-0 bg-gradient-to-r from-violet-600 to-indigo-600"
          />
        )}
      </AnimatePresence>
      {cloneElement(props.icon as React.ReactElement, {
        className:
          "w-2/3 h-2/3 absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] ",
        color: "white",
      })}
    </button>
  );
};

export default CircleButton;

/*
 {cloneElement(props.icon as React.ReactElement, {
        className:
          "w-2/3 h-2/3 absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] hover:scale-150 transition-all duration-150",
        color: "white",
      })}

*/
