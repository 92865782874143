import { cloneElement } from "react";

interface NakedSmallButtonProps {
  icon: React.ReactNode;
  onClick?: () => void;
  color?: string;
  iconClassName?: string;
}


const NakedSmallButton = (props: NakedSmallButtonProps) => {
  const iconStyle = props.iconClassName || "";
  return (
    <div onClick={props.onClick} className=" w-full flex justify-center cursor-pointer active:scale-95 active:translate-y-[2px] transition-all duration-150 ">
      {cloneElement(props.icon as React.ReactElement, {
        className: `h-6 w-6 translate-x-[-20%] ${iconStyle}`,
        color: props.color || "white",
      })}
    </div>
  );
};

export default NakedSmallButton;
