import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import {
  DialogDescription,
  DialogTitle,
} from "@radix-ui/react-dialog";

interface DialogContentProps {
  trigger: React.ReactNode;
  children: React.ReactNode;
  className?: string;
  dialogHeader?: string;
  dialogDescription?: string;
  dialogAction?: React.ReactNode;
}

const CostumeDialog = (props: DialogContentProps) => {
  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button className="w-fit h-fit bg-transparent hover:bg-transparent">
          {props.trigger}{" "}
        </Button>
      </DialogTrigger>
      <DialogContent className=" w-full top-56   p-1 bg-transparent rounded-sm m-0">
        {" "}
     {props.children}
      </DialogContent>
    </Dialog>
  );
};
export default CostumeDialog;
